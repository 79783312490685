import { ResponseV3 } from '@/types/API.type'
import { BillingAddress } from '@/types/BillingAddress.type'
import { CancelSubscriptionForm } from '@/types/forms/account-api/CancelSubscriptionForm.type'
import { ChangeSubcriptionBillingPeriodForm } from '@/types/forms/account-api/ChangeSubcriptionBillingPeriodForm.type'
import { CompleteFutureLeadersPassForm } from '@/types/forms/account-api/FutureLeadersPassForm.type'
import { SubscribeFutureLeadersPassForm } from '@/types/forms/account-api/SubscribeFutureLeadersPassForm'
import { OffboardingClub } from '@/types/Offboarding.type'
import { GetInvoicesResponses } from '@/types/response/account-api/Invoices.type'
import { NotificationPreferences } from '@/types/response/account-api/Notification.type'
import { Subscription, SubscriptionsMeta } from '@/types/response/account-api/Subscription.type'
import { SubscriptionCollectNow, SubscriptionEstimate } from '@/types/response/account-api/SubscriptionEstimate.type'
import { BsPlansIds } from '@/vars/ChargebeeAttr'
import { stringifyLocations } from '../helpers/stringify-locations'
import Http from './ApiClient'

class AccountApi {
    static async getSubscriptions(): Promise<ResponseV3<Subscription[], SubscriptionsMeta>> {
        const response = await Http.get(`v3/subscriptions`)

        return response.data
    }

    static async subscribeFutureLeadersPass(form: SubscribeFutureLeadersPassForm) {
        const response = await Http.post(
            `/v3/subscriptions/future-leaders-pass`,
            stringifyLocations(form as Record<string, unknown>)
        )

        return response.data
    }

    static async getInvoices(): Promise<ResponseV3<GetInvoicesResponses>> {
        const response = await Http.get(`/v3/invoices`)

        return response.data
    }

    static async getBillingAddress(): Promise<ResponseV3<BillingAddress | null, SubscriptionsMeta>> {
        const response = await Http.get(`/v3/subscriptions/billing-address`)

        return response.data
    }

    static async patchBillingAddress(billing: BillingAddress): Promise<ResponseV3<BillingAddress>> {
        const response = await Http.patch(`v3/subscriptions/billing-address`, billing)

        return response.data
    }

    static async cancelSubscriptionAddon(subscriptionId: string, addonId: string): Promise<void> {
        const response = await Http.delete(`v3/subscriptions/${subscriptionId}/cancel/${addonId}`)

        return response.data
    }

    static async changeBillingPeriod(form: ChangeSubcriptionBillingPeriodForm): Promise<void> {
        const response = await Http.put(`/v3/subscriptions/${form.subscriptionId}/billing-period`, {
            period: form.period
        })

        return response.data
    }

    static async completeFutureLeadersPass(form: CompleteFutureLeadersPassForm): Promise<void> {
        const response = await Http.post(`/v3/subscriptions/complete-future-leaders-pass`, stringifyLocations(form))

        return response.data
    }

    static async cancelSubscription(
        subscriptionId: string,
        cancelSubscriptionForm: CancelSubscriptionForm
    ): Promise<ResponseV3<Subscription>> {
        const response = await Http.delete(`v3/subscriptions/${subscriptionId}/cancel`, {
            data: cancelSubscriptionForm
        })

        return response.data
    }

    static async reactivateSubscription(subscriptionId: string): Promise<ResponseV3<Subscription>> {
        const response = await Http.post(`v3/subscriptions/${subscriptionId}/reactivate`)

        return response.data
    }

    static async switchOnAutoCollectionSubscription(subscriptionId: string): Promise<ResponseV3<Subscription>> {
        const response = await Http.post(`v3/subscriptions/${subscriptionId}/auto-collection`)

        return response.data
    }

    static async unscheduleCancellationSubscription(subscriptionId: string): Promise<ResponseV3<Subscription>> {
        const response = await Http.post(`v3/subscriptions/${subscriptionId}/unschedule-cancellation`)

        return response.data
    }

    static async getSubscriptionRenewEstimate(subscriptionId: string): Promise<ResponseV3<SubscriptionEstimate>> {
        const response = await Http.get(`v3/subscriptions/${subscriptionId}/estimate`)

        return response.data
    }

    static async getChargebeeCollectNowPayload(): Promise<ResponseV3<SubscriptionCollectNow>> {
        const response = await Http.get(`/v3/subscriptions/collect-now`, {})

        return response.data
    }

    static async upgradeSubscription(
        subscriptionId: string,
        planItemPriceId?: string | { addonItemPriceId: string }
    ): Promise<ResponseV3<unknown>> {
        const response = await Http.put(`v3/subscriptions/${subscriptionId}`, {
            planItemPriceId
        })

        return response.data
    }
    static async getSubscriptionData(
        subscriptionId: string,
        params: { planItemPriceId?: string; addonItemPriceId: string | undefined; context?: string }
    ): Promise<ResponseV3<Record<string, unknown>>> {
        const response = await Http.put(`/v3/subscriptions/${subscriptionId}`, params)

        return response.data
    }

    static async upgradeSubscriptionBs(
        subscriptionId: string,
        addonItemPriceId: string,
        additionnalAddonItemPriceId: string | null
    ): Promise<
        ResponseV3<{
            planItemPriceId: BsPlansIds
            addonItemPriceId: string
            additionnalAddonItemPriceId: string
            context: string
        }>
    > {
        const response = await Http.put(`v3/subscriptions/${subscriptionId}`, {
            addonItemPriceId,
            additionnalAddonItemPriceId
        })

        return response.data
    }

    static async getSubscription(subscriptionId: string): Promise<ResponseV3<Subscription>> {
        const response = await Http.get(`v3/subscriptions/${subscriptionId}`, {})

        return response.data
    }

    static async pauseSubscription(subscriptionId: string, pauseDuration: number): Promise<ResponseV3<Subscription>> {
        const response = await Http.put(`v3/subscriptions/${subscriptionId}/pause`, { pauseDuration })

        return response.data
    }
    static async getNotificationPreferences(): Promise<ResponseV3<NotificationPreferences>> {
        const response = await Http.get(`/v3/notifications`)

        return response.data
    }

    static async getOffboardingStats(): Promise<ResponseV3<OffboardingClub>> {
        const response = await Http.get(`v3/subscriptions/offboarding`)

        return response.data
    }
    static async setNotificationPreferences(params: {
        topics?: { id: number; subscribed: boolean }[]
        unsubscribed: boolean
    }): Promise<void> {
        const response = await Http.post(`/v3/notifications`, params)

        return response.data
    }

    static async downgradeSubscription(subscriptionId: string): Promise<ResponseV3<unknown>> {
        const response = await Http.put(`v3/subscriptions/${subscriptionId}/downgrade`)

        return response.data
    }
}

export default AccountApi
