export enum ButtonType {
    BUTTON = 'button',
    SUBMIT = 'submit',
    LINK = 'a',
    ROUTER_LINK = 'RouterLink'
}

export enum ButtonTheme {
    DISCRET = 'discret',
    PRIMARY = 'primary',
    GLASS = 'glass',
    SECONDARY = 'secondary',
    TERTIARY = 'tertiary',
    GHOST = 'ghost',
    SIMPLE = 'simple',
    SUCCESS = 'success',
    ROYALE = 'royale'
}

export enum ButtonSize {
    SMALL = 'small',
    NORMAL = 'normal',
    LARGE = 'large'
}

export enum ButtonIconPosition {
    RIGHT = 'right',
    LEFT = 'left'
}
