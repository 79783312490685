import { ClubRouteName, EventRouteName, RouteName } from '@/vars/RouteName'
import { SpaName } from '@/vars/SpaAttr'
import {
    createRouter,
    createWebHistory,
    RouteLocationNamedRaw,
    useRouter,
    RouteLocation,
    RouteRecordRaw
} from 'vue-router'
import AppRoutes from '@/routes/app-routes'
import ProRoutes from '@/routes/pro-routes'
import ClubRoutes from '@/routes/club-routes'
import { BusinessLineName } from '@/vars/AuthAttr'
import { useAuthStore } from '@/libs/stores/auth.store'
import { EventCardType } from '@/types/EventCard.type'
import { ResourceType } from '@/vars/ResourceAttr'
import { EventType } from '@/vars/EventAttr'
import AcademyRoutes from '@/routes/academy-routes'

export type RouteLocationPotentiallyNamedWithFullUrl = (RouteLocation | RouteLocationNamedRaw) & { url?: string }

type RedirectBounding = {
    is: string
    tag: string
    href?: string
    target?: string
    to?: RouteLocationPotentiallyNamedWithFullUrl
    rel?: string
}

type RedirectBoundingMember = {
    member?: {
        idFF?: string
        idff?: string
        id?: string
        businessLines?: BusinessLineName[]
    }
}

type RedirectBoundingEvent = {
    event?: Pick<EventCardType, 'isClub' | 'id' | 'slug' | 'eventType'>
}

type RedirectBoundingRessource = {
    resource: {
        type: ResourceType | string
        slug: string
    }
}

type SpaMapper = {
    [key in SpaName]: {
        routes: RouteRecordRaw[]
        baseUrl: string
    }
}

const ROUTER_HISTORY = createWebHistory()

// Ce hook permet de déterminer la route ou la redirection à faire selon la route demander et la spa dans laquelle on se trouve
export function useNavigationResolver() {
    const navigateRouter = useRouter()

    function determineLink(
        route: RouteLocation | RouteLocationNamedRaw,
        spa: SpaName = import.meta.env.VITE_SPA_RUNNING
    ): RouteLocationPotentiallyNamedWithFullUrl | string {
        const spaMapper: SpaMapper = {
            [SpaName.LENETWORK]: {
                routes: AppRoutes,
                baseUrl: import.meta.env.VITE_APP_BASE_URL
            },
            [SpaName.LECLUB]: {
                routes: ClubRoutes,
                baseUrl: import.meta.env.VITE_CLUB_BASE_URL
            },
            [SpaName.PRO]: {
                routes: ProRoutes,
                baseUrl: import.meta.env.VITE_PRO_BASE_URL
            },
            [SpaName.ACADEMY]: {
                routes: AcademyRoutes,
                baseUrl: import.meta.env.VITE_ACADEMY_BASE_URL
            },
            [SpaName.STORYBOOK]: {
                routes: [],
                baseUrl: ''
            }
        }
        const router = createRouter({
            history: ROUTER_HISTORY,
            routes: spaMapper[spa || import.meta.env.VITE_SPA_RUNNING].routes
        })

        let foundRouteInCurrentSpa: RouteLocation | null = null

        try {
            foundRouteInCurrentSpa = router.resolve(route)
        } catch {
            foundRouteInCurrentSpa = null
        }

        if (foundRouteInCurrentSpa) {
            return { ...foundRouteInCurrentSpa, url: spaMapper[spa].baseUrl + foundRouteInCurrentSpa.fullPath }
        } else {
            for (const spa of Object.keys(spaMapper) as SpaName[]) {
                // on créer un router à la volée afin d'utiliser le router.resolve qui constuit le fullPath propre avec les params + queryParams
                const routerSpa = createRouter({
                    history: ROUTER_HISTORY,
                    routes: spaMapper[spa].routes
                })
                let foundRouteInSpa: RouteLocation | null = null
                try {
                    foundRouteInSpa = routerSpa.resolve(route)
                } catch {
                    foundRouteInSpa = null
                }
                if (foundRouteInSpa) {
                    return spaMapper[spa].baseUrl.concat(foundRouteInSpa.fullPath)
                }
            }

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            throw new Error(`No route found for: ${route.name} with params '${JSON.stringify(route)}'`)
        }
    }
    /**
     * redirectBounding({ member })
     * redirectBounding({ event })
     * redirectBounding({ name: RouteName.CLUB_DETAILS, params: { text: 123 } })
     */
    function redirectBounding(
        route:
            | RouteLocation
            | RouteLocationNamedRaw
            | RedirectBoundingMember
            | RedirectBoundingEvent
            | RedirectBoundingRessource
            | null,
        opts?: { target?: '_blank'; spa?: SpaName; context?: RedirectBoundingMember | RedirectBoundingEvent }
    ): RedirectBounding {
        if (route && 'member' in route) {
            return redirectBoundingToMember(route)
        } else if (route && 'event' in route) {
            return redirectBoundingToEventDetails(route)
        } else if (route && 'resource' in route) {
            return redirectBoundingToResource(route)
        } else {
            // @ts-expect-error Devrait être réglé dans les futures version de Typescript
            return redirectBoundingDefault(route, opts)
        }
    }

    const navigate = (
        route: RouteLocation | RouteLocationNamedRaw,
        opts?: { replace?: boolean; forceReload?: boolean }
    ) => {
        const linkResolved = determineLink(route)

        if (typeof linkResolved === 'string') {
            window.location.href = linkResolved
        } else {
            if (opts?.replace) {
                navigateRouter.replace(linkResolved)
            } else {
                navigateRouter.push(linkResolved)
            }
        }

        if (opts?.forceReload) {
            window.location.reload()
        }
    }

    function redirectBoundingToMember(params?: RedirectBoundingMember): RedirectBounding {
        const privateUrl = import.meta.env.VITE_PRIVATE_BASE_URL
        const spaRunning = import.meta.env.VITE_SPA_RUNNING
        const appUrl = import.meta.env.VITE_APP_BASE_URL

        const store = useAuthStore()

        if (
            !params ||
            !params.member ||
            (!params.member?.idFF && !params.member.idff && !params.member.id) ||
            !store.isLoggedIn
        ) {
            return {
                is: 'div',
                tag: 'div'
            }
        }

        switch (spaRunning) {
            case SpaName.LECLUB:
                if (
                    store.hasBusinessLine(BusinessLineName.LECLUB) &&
                    params.member.businessLines?.includes(BusinessLineName.LECLUB)
                ) {
                    return {
                        is: 'a',
                        tag: 'a',
                        target: '_blank',
                        href: `${privateUrl}/member/${params.member?.idFF || params.member?.idff}`
                    }
                } else {
                    return {
                        is: 'a',
                        tag: 'a',
                        href: `${appUrl}/member/${params.member?.id || params.member?.idff || params.member?.idFF}`
                    }
                }
            case SpaName.LENETWORK:
                return {
                    is: 'RouterLink',
                    tag: 'RouterLink',
                    to: {
                        name: RouteName.MEMBERS_PROFIL,
                        params: {
                            memberId: params.member?.id || params.member?.idff || params.member?.idFF || ''
                        }
                    }
                }
            default:
                return {
                    is: 'a',
                    tag: 'a',
                    href: `${appUrl}/member/${params.member?.id || params.member?.idff || params.member?.idFF}`
                }
        }
    }

    function redirectBoundingToEventDetails(params: RedirectBoundingEvent): RedirectBounding {
        const clubUrl = import.meta.env.VITE_CLUB_BASE_URL
        const spaRunning = import.meta.env.VITE_SPA_RUNNING
        const appUrl = import.meta.env.VITE_APP_BASE_URL

        const authStore = useAuthStore()
        const event = params.event

        if (!event || !event?.id) {
            return {
                is: 'div',
                tag: 'div'
            }
        }

        if (event?.eventType === EventType.NETWORKING) {
            if ([SpaName.LENETWORK, SpaName.LECLUB].includes(import.meta.env.VITE_SPA_RUNNING)) {
                return {
                    is: 'RouterLink',
                    tag: 'RouterLink',
                    to: {
                        name: EventRouteName.EVENT_NETWORKING_SINGLE,
                        params: { slug: event?.id }
                    }
                }
            } else {
                return {
                    is: 'a',
                    tag: 'a',
                    href: `${import.meta.env.VITE_APP_BASE_URL}/networking/${event?.id}`,
                    target: '_blank',
                    rel: 'noopener noreferrer'
                }
            }
        }

        switch (spaRunning) {
            case SpaName.LECLUB:
                return {
                    is: 'RouterLink',
                    tag: 'RouterLink',
                    to: {
                        name: EventRouteName.EVENT_DETAILS,
                        params: { id: event?.id, slug: event?.slug || '' }
                    }
                }
            case SpaName.LENETWORK:
                if (event.isClub && authStore.isLoggedIn && authStore.hasBusinessLine(BusinessLineName.LECLUB)) {
                    return {
                        is: 'a',
                        tag: 'a',
                        href: `${clubUrl}/event/${event?.id}/${event?.slug || ''}`
                    }
                }
                return {
                    is: 'RouterLink',
                    tag: 'RouterLink',
                    to: {
                        name: EventRouteName.EVENT_DETAILS,
                        params: { id: event?.id, slug: event?.slug || '' }
                    }
                }

            default:
                return {
                    is: 'a',
                    tag: 'a',
                    href: `${appUrl}/event/${event?.id}/${event?.slug || ''}`
                }
        }
    }

    function redirectBoundingToResource(params: RedirectBoundingRessource): RedirectBounding {
        const { type, slug } = params.resource
        if (import.meta.env.VITE_SPA_RUNNING === SpaName.LECLUB) {
            switch (type) {
                case ResourceType.PODCAST:
                    return {
                        tag: 'RouterLink',
                        is: 'RouterLink',
                        to: { name: ClubRouteName.PODCAST_SINGLE, params: { slug: slug } }
                    }
                case ResourceType.VIDEO:
                    return {
                        tag: 'RouterLink',
                        is: 'RouterLink',
                        to: { name: ClubRouteName.VIDEO_SINGLE, params: { slug: slug } }
                    }
                default:
                    return {
                        tag: 'RouterLink',
                        is: 'RouterLink',
                        to: { name: ClubRouteName.ARTICLE_SINGLE, params: { id: slug } }
                    }
            }
        } else {
            const redirectTag = {
                tag: import.meta.env.VITE_SPA_RUNNING === SpaName.LENETWORK ? 'RouterLink' : 'a',
                is: import.meta.env.VITE_SPA_RUNNING === SpaName.LENETWORK ? 'RouterLink' : 'a'
            }
            switch (type) {
                case ResourceType.PODCAST: {
                    const linkResolved = determineLink({ name: RouteName.PODCAST_SINGLE, params: { slug: slug } })
                    const linkObject = typeof linkResolved === 'string' ? { href: linkResolved } : { to: linkResolved }

                    return {
                        ...redirectTag,
                        ...linkObject
                    }
                }
                case ResourceType.VIDEO: {
                    const linkResolved = determineLink({ name: RouteName.VIDEO_SINGLE, params: { slug: slug } })
                    const linkObject = typeof linkResolved === 'string' ? { href: linkResolved } : { to: linkResolved }

                    return {
                        ...redirectTag,
                        ...linkObject
                    }
                }

                case ResourceType.FOLDER: {
                    const linkResolved = determineLink({ name: RouteName.FOLDER_SINGLE, params: { folderSlug: slug } })
                    const linkObject = typeof linkResolved === 'string' ? { href: linkResolved } : { to: linkResolved }

                    return {
                        ...redirectTag,
                        ...linkObject
                    }
                }
                case ResourceType.ARTICLE:
                default: {
                    const linkResolved = determineLink({ name: RouteName.ARTICLE_SINGLE, params: { slug: slug } })
                    const linkObject = typeof linkResolved === 'string' ? { href: linkResolved } : { to: linkResolved }

                    return {
                        ...redirectTag,
                        ...linkObject
                    }
                }
            }
        }
    }

    function redirectBoundingDefault(
        route: RouteLocation | RouteLocationNamedRaw | null,
        opts?: { target?: '_blank'; spa?: SpaName }
    ): RedirectBounding {
        if (!route)
            return {
                tag: 'div',
                is: 'div'
            }
        const linkResolved = determineLink(route, opts?.spa)
        if (typeof linkResolved === 'string') {
            return {
                tag: 'a',
                is: 'a',
                href: linkResolved,
                ...opts
            }
        } else {
            return {
                tag: 'RouterLink',
                is: 'RouterLink',
                to: linkResolved,
                ...opts
            }
        }
    }

    return {
        redirectBounding,
        navigate
    }
}
