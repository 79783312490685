import { DateTime } from 'luxon'

export default function useFutureLeadersPass() {
    const maximumBirthDate = DateTime.now().minus({ years: 15 }).toISO()

    const minimumBirthDate = DateTime.now().minus({ years: 27 }).toISO()

    const minimumBirthDateImageAuthorization = DateTime.now().minus({ years: 21 }).toISO()

    function needImageAuthorization(birthDate: string) {
        return DateTime.fromJSDate(new Date(birthDate)).toString() > minimumBirthDateImageAuthorization.toString()
    }

    return {
        maximumBirthDate,
        minimumBirthDate,
        needImageAuthorization
    }
}
