import { useI18n } from 'vue-i18n'
import { Price } from '@/types/Price.type'

export const regexIsUrl =
    /((?:https?:\/\/)?(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{2,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&/=]*))/gim

export const regexIsEmail = /^[\w\-.]+@([\w-]+\.)+[\w-]+/gim

// eslint-disable-next-line
export const regexContainSpecial = /[`!@#$%^&*()_\-+=\[\]{};':"\\|,.<>\/?~]+/

// eslint-disable-next-line
export const regexContainNumber = /\d+/

export function removeHtmlTags(html: string) {
    return html.replaceAll(/(<([^>]+)>)/gim, '')
}

export const FREE_PRICE: Price = {
    amount: '0',
    currency: 'USD',
    value: 0
}

export function escapeHtml(text: string) {
    return text.replace(/[&<>"'/]/g, (char) => {
        switch (char) {
            case '&':
                return '&amp;'
            case '<':
                return '&lt;'
            case '>':
                return '&gt;'
            case '"':
                return '&quot;'
            case '\\':
                return '&#39;'
            case '/':
                return '&#x2F;'
            default:
                return char
        }
    })
}

export function unescapeHtml(text: string) {
    return text.replace(/&amp;|&lt;|&gt;|&quot;|&#39;|&#x2F;/g, (entity) => {
        switch (entity) {
            case '&amp;':
                return '&'
            case '&lt;':
                return '<'
            case '&gt;':
                return '>'
            case '&quot;':
                return '"'
            case '&#39;':
                return "'"
            case '&#x2F;':
                return '/'
            default:
                return entity
        }
    })
}

export function stripTags(html: string) {
    return html.replace(/<[^>]*>/g, '')
}

export function formatPrice(price?: Price, lang?: string, forceZero = false) {
    const { t } = useI18n()

    if (!price) {
        return ''
    }

    if (price.value === 0 && !forceZero) {
        return t('free')
    }

    return Number(price.amount)
        .toLocaleString(lang, {
            minimumFractionDigits: 0
        })
        .concat(` ${price.currency}`)
}

export function formatCurrency(currency: string) {
    switch (currency) {
        case 'EUR':
            return '€'
        case 'USD':
            return '$'
        case 'GBP':
            return '£'
        default:
            return currency
    }
}

export function shortenString(sentence: string, size?: number): string {
    if (!size) {
        return sentence
    }

    if (sentence.length > size) {
        return sentence.substring(0, size) + '...' // Il est possible d'améliorer cette fonction en ajoutant une reconnaissance de la ponctuation / de la différence entre size et la valeur du prochain espace
    } else {
        return sentence
    }
}
