<script setup lang="ts">
    import { computed, ref } from 'vue'
    import { useQuery } from '@tanstack/vue-query'
    import { RuleExpression, useField } from 'vee-validate'
    import { v4 as uuid } from 'uuid'
    import { Loader } from '@googlemaps/js-api-loader'
    import BaseField from '@/components/ui/basics/BaseField.vue'
    import Multiselect from 'vue-multiselect'
    import { toMilliseconds } from '@/libs/helpers/numbers'
    import { refDebounced } from '@vueuse/core'
    import { SpaName } from '@/vars/SpaAttr'
    import FormError from './FormError.vue'
    import { LocationPlaceType } from '@/types/Geoloc.type'
    import BaseTag from '../basics/BaseTag.vue'
    import { useI18n } from 'vue-i18n'

    type ModelValue = google.maps.places.PlaceResult | google.maps.places.PlaceResult[] | null

    defineModel<ModelValue>()

    const props = withDefaults(
        defineProps<{
            name?: string
            rules?: RuleExpression<ModelValue>
            multiple?: boolean
            autocompleteTypes?: LocationPlaceType[]
            testId?: string
            fields?: string[]
            placeholder?: string
        }>(),
        {
            name: undefined,
            testId: undefined,
            modelValue: undefined,
            rules: undefined,
            placeholder: undefined,
            autocompleteTypes: () => ['(regions)'],
            fields: () => [
                'place_id',
                'address_components',
                'formatted_address',
                'geometry',
                'name',
                'types',
                'place_id'
            ]
        }
    )

    defineEmits<{
        'update:modelValue': [value: ModelValue]
    }>()

    const { t, locale } = useI18n()

    const name = computed(() => props.name || uuid())

    const mutliselectClass = computed(() => {
        switch (import.meta.env.VITE_SPA_RUNNING) {
            case SpaName.LENETWORK:
                return `multiselect--network`
            default:
                return ''
        }
    })

    const isRequired = computed(() => {
        if (!props.rules) {
            return false
        } else if (typeof props.rules === 'string') {
            return props.rules.includes('required')
        } else {
            return Object.keys(props.rules).includes('required')
        }
    })

    const { value, errorMessage } = useField<ModelValue>(name.value, props.rules, {
        standalone: !props.name,
        syncVModel: true
    })

    const search = ref('')
    const searchDebounced = refDebounced(search, 500)

    const { data: googleAutocompleteService } = useQuery({
        queryKey: ['INIT_GOOGLE_API', import.meta.env.VITE_APP_GMAPS_API_KEY],
        queryFn: async () => {
            const googleMapLoader = new Loader({
                apiKey: import.meta.env.VITE_APP_GMAPS_API_KEY,
                language: locale.value || 'fr',
                region: 'FR',
                version: 'weekly',
                libraries: ['places']
            })

            const google = await googleMapLoader.load()
            return new google.maps.places.AutocompleteService()
        },
        staleTime: toMilliseconds({ minutes: 4 })
    })

    const { data: placePredictions, isFetching: isFetchingPredictions } = useQuery({
        queryKey: ['GOOGLE_AUTOCOMPLETE_SEARCH', searchDebounced],
        queryFn: async () => {
            if (!googleAutocompleteService.value) {
                throw new Error('Missing "googleAutocompleteService.value"')
            }
            const predictions = await googleAutocompleteService.value.getPlacePredictions({
                input: searchDebounced.value,
                types: props.autocompleteTypes
            })

            const places = await Promise.all(
                predictions.predictions.map((prediction) => getGooglePlaceDetails(prediction.place_id))
            )

            return places.filter((place) => place !== null)
        },
        enabled: () => !!googleAutocompleteService.value
    })

    function getGooglePlaceDetails(placeId: string) {
        return new Promise<google.maps.places.PlaceResult | null>((resolve, reject) => {
            const placeService = new google.maps.places.PlacesService(document.createElement('div'))

            placeService.getDetails(
                {
                    placeId: placeId,
                    fields: props.fields
                },
                (placeResult, placeResultStatus) => {
                    if (placeResult && placeResultStatus === google.maps.places.PlacesServiceStatus.OK) {
                        resolve(placeResult)
                    } else {
                        // Attention à gérer
                        reject(placeResult)
                    }
                }
            )
        })
    }

    function onRemove(place: google.maps.places.PlaceResult) {
        if (props.multiple) {
            if (Array.isArray(value.value)) {
                value.value = value.value.filter(
                    (alreadySelectedValue) => alreadySelectedValue.place_id !== place.place_id
                )
            } else {
                value.value = []
            }
        } else {
            value.value = null
        }
    }

    function onSelect(place: google.maps.places.PlaceResult) {
        if (props.multiple) {
            if (Array.isArray(value.value)) {
                value.value = [...value.value, place]
            } else {
                value.value = [place]
            }
        } else {
            value.value = place
        }
    }
</script>

<template>
    <BaseField class="field" :required="isRequired">
        <template #label>
            <slot name="label" />
        </template>
        <template #default>
            <Multiselect
                class="field__multiselect"
                :data-test-id="testId"
                :class="mutliselectClass"
                :model-value="value"
                :multiple="multiple"
                :taggable="multiple"
                :loading="isFetchingPredictions"
                :options="placePredictions ?? []"
                label="formatted_address"
                :internal-search="false"
                searchable
                preserve-search
                hide-selected
                :select-label="t('select_label')"
                :selected-label="t('selected')"
                :deselect-label="t('enter_to_remove')"
                :tag-placeholder="t('tag_placeholder')"
                :placeholder="placeholder ?? t('placeholder')"
                @search-change="search = $event ?? ''"
                @remove="onRemove"
                @select="onSelect"
            >
                <template #tag="{ option }">
                    <BaseTag
                        :data-testid="`selected-tag-${option.place_id}`"
                        class="mr-4 whitespace-nowrap"
                        can-remove="right"
                        @remove="onRemove(option)"
                    >
                        {{ option.formatted_address }}
                    </BaseTag>
                </template>
                <template #noOptions>
                    <span v-if="isFetchingPredictions">{{ t('loading') }}</span>
                    <span v-else-if="search?.length === 0">{{ t('start_searching') }}</span>
                    <span v-else>{{ t('empty_list') }}</span>
                </template>
                <template #noResult>
                    <span>{{ isFetchingPredictions ? t('loading') : t('no_results_found') }}</span>
                </template>
            </Multiselect>
            <FormError v-if="errorMessage">
                {{ errorMessage }}
            </FormError>
        </template>
    </BaseField>
</template>

<i18n lang="json">
{
    "fr": {
        "select_label": "Sélectionner",
        "selected": "Sélectionné",
        "enter_to_remove": "Supprimer (Entrer)",
        "tag_placeholder": "",
        "placeholder": "Saisir une localisation",
        "empty_list": "La liste est vide",
        "start_searching": "Tapez une recherche",
        "loading": "Chargement des résultats...",
        "no_results_found": "Aucun résultat n'a été trouvé"
    },
    "en": {
        "select_label": "Select",
        "selected": "Selected",
        "enter_to_remove": "Remove (Enter)",
        "tag_placeholder": "",
        "placeholder": "Type a location",
        "empty_list": "No results found",
        "start_searching": "Type something to search",
        "loading": "Loading results ...",
        "no_results_found": "No result found"
    }
}
</i18n>
