<script setup lang="ts">
    import { useI18n } from 'vue-i18n'
    import CardWrapper from '@/components/ui/wrappers/cards/CardWrapper.vue'
    import CardBody from '@/components/ui/wrappers/cards/CardBody.vue'
    import BaseLink from '@/components/ui/actions/BaseLink.vue'
    import BaseButton from '@/components/ui/actions/BaseButton.v2.vue'
    import { LinkTheme } from '@/vars/LinkAttr'
    import useUpsellStake from '@/libs/compositions/useUpsellStake'
    import QueryStateManager from '@/components/ui/queries/QueryStateManager.vue'
    import { useSegment } from '@/libs/vendors/segment/segment'
    import { StakeKey } from '@/types/UpsellStake.type'
    import useGettingStartedTodoQuery from '@/libs/queries/useGettingStartedTodoQuery'

    defineEmits<{
        close: []
    }>()

    const {
        data: todoList,
        isLoading: isLoadingTodoList,
        isError: isErrorTodoList
    } = useGettingStartedTodoQuery({
        refetchOnMount: true
    })

    const { getColor } = useUpsellStake()
    const { analytics } = useSegment()
    const { t } = useI18n()

    function trackActionClick(stakeKey?: StakeKey) {
        if (!stakeKey) {
            return null
        }

        analytics.track('community:onboarding:upsell_stakes:link_open', { stake: stakeKey })
    }
</script>

<template>
    <section class="flex flex-col gap-6">
        <QueryStateManager :is-loading="isLoadingTodoList" :is-error="isErrorTodoList" :is-success="!!todoList?.data">
            <template #success>
                <div class="flex flex-col gap-4">
                    <CardWrapper
                        v-for="todo in todoList?.data"
                        :key="todo.id"
                        shadowless
                        :style="{ background: getColor(todo.meta?.stake).light }"
                    >
                        <CardBody class="flex flex-col items-start">
                            <h2 class="title-4 mb-2r">{{ todo.title }}</h2>
                            <p>{{ todo.description }}</p>
                            <BaseLink
                                class="mt-6 self-end"
                                :theme="LinkTheme.IMPORTANT"
                                :href="todo.cta.url"
                                rel="noopener noreferrer"
                                @click="trackActionClick(todo.meta?.stake)"
                            >
                                {{ todo.cta.title }}
                            </BaseLink>
                        </CardBody>
                    </CardWrapper>
                </div>
            </template>
        </QueryStateManager>
        <div class="flex justify-center">
            <BaseButton class="!px-10" test-id="close-stakes" @click="$emit('close')">{{ t('close') }}</BaseButton>
        </div>
    </section>
</template>

<i18n lang="json">
{
    "fr": {
        "close": "Fermer"
    },
    "en": {
        "close": "Fermer"
    }
}
</i18n>
