import { computed } from 'vue'
import { useRouter } from 'vue-router'
import i18n from '@/i18n'
import useGroupsQuery from '@/libs/queries/useGroupsQuery'
import { useAuthStore } from '@/libs/stores/auth.store'
import { HeaderNavigation } from '@/types/Header.type'
import { RoleName } from '@/vars/AuthAttr'
import { RouteName } from '@/vars/RouteName'
import messages from './i18n/profile.json'

i18n.global.mergeLocaleMessage('fr', messages.fr)
i18n.global.mergeLocaleMessage('en', messages.en)

const { t } = i18n.global
const adminUrl = import.meta.env.VITE_ADMIN_BASE_URL

export function useProfileNetworkNavigation() {
    const store = useAuthStore()
    const router = useRouter()
    const { data } = useGroupsQuery({ isJoined: true })

    const myCircles = computed(() => (data.value?.data ? data.value?.data : []))

    const navigationsItems = computed<HeaderNavigation[]>(() => {
        const items: HeaderNavigation[] = []

        if (store.hasRole(RoleName.ADMIN)) {
            items.push({
                label: t('nav.admin'),
                children: [
                    {
                        label: t('nav.admin_dashboard'),
                        href: `${adminUrl}/dashboard`
                    }
                ]
            })
        }

        if (router.currentRoute.value?.matched?.[0]?.name !== RouteName.ONBOARDING_BASE) {
            items.push({
                label: t('nav.account'),
                children: [
                    {
                        label: t('nav.profile_network'),
                        route: { name: RouteName.MEMBER_ME }
                    },
                    {
                        label: t('nav.subscriptions'),
                        route: { name: RouteName.ACCOUNT_MEMBERSHIP }
                    },
                    {
                        label: t('nav.login_details'),
                        action() {
                            router.push({ name: RouteName.ACCOUNT_INFO })
                        }
                    }
                ]
            })
        }

        if (myCircles.value.length > 0) {
            items.push({
                label: t('nav.circles'),
                children: myCircles.value.map((circle) => {
                    return {
                        label: circle.name,
                        route: { name: RouteName.CIRCLE_SINGLE, params: { slug: circle.slug } }
                    }
                })
            })
        }

        if (store.isOnSwitchTo) {
            items.push({
                children: [
                    {
                        label: t('nav.back_admin_user'),
                        action: () => {
                            store.exitSwitchToUser()
                        }
                    },
                    {
                        label: t('nav.logout'),
                        action() {
                            store.logout()
                        }
                    }
                ]
            })
        } else {
            items.push({
                children: [
                    {
                        label: t('nav.logout'),
                        action() {
                            store.logout()
                        }
                    }
                ]
            })
        }

        return items
    })

    return {
        items: navigationsItems
    }
}
