import { computed, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useQuery } from '@tanstack/vue-query'
import { useTimeoutFn, watchImmediate } from '@vueuse/core'
import { toMilliseconds } from '@/libs/helpers/numbers'
import { QueryName } from '@/vars/QueryAttr'
import { ToastType } from '@/vars/ToastAttr'
import useNotification from './useNotification'

export default function useNewAppReleaseDetector() {
    const { snackbar } = useNotification()

    const enableNewAppReleaseLongPool = ref(false)
    const currentIndexBundleName = ref('')
    const { t } = useI18n({
        messages: {
            fr: {
                update_available: 'Une mise à jour est disponible',
                update: 'Mettre à jour'
            },
            en: {
                update_available: 'An update is available',
                update: 'Update'
            }
        }
    })

    onMounted(() => {
        document.head.querySelectorAll<HTMLScriptElement>('script[type="module"]')?.forEach((script) => {
            if (script.src.includes('/assets/index-')) {
                currentIndexBundleName.value = script.src?.split('/assets')?.[1]
                enableNewAppReleaseLongPool.value = true
            }
        })
    })

    const { data: html } = useQuery({
        refetchInterval: 120_000,
        enabled: enableNewAppReleaseLongPool,
        queryKey: [QueryName.RELEASE_DETECTOR],
        queryFn: async () => {
            const response = await fetch(window.location.href)

            if (!response.ok) {
                throw new Error(await response.text())
            }

            return await response.text()
        }
    })

    const isUpdateAvailable = computed<boolean>(() =>
        Boolean(currentIndexBundleName.value && !html.value?.includes(currentIndexBundleName.value))
    )

    /**
     * Toutes les 24h on vérifie si une update du frontend est disponible, si c'est le cas on fait un refresh de la page
     */
    const { start: startTimer } = useTimeoutFn(
        () => {
            if (isUpdateAvailable.value) {
                window.location.reload()
            }
        },
        toMilliseconds({ hours: 24 }),
        { immediate: false }
    )

    watchImmediate(html, () => {
        if (!html.value) {
            return null
        }

        if (isUpdateAvailable.value && enableNewAppReleaseLongPool.value) {
            enableNewAppReleaseLongPool.value = false
            startTimer()
            snackbar({
                type: ToastType.SUCCESS,
                text: t('update_available'),
                duration: -1,
                action: {
                    text: t('update'),
                    handler() {
                        window.location.reload()
                    }
                }
            })
        }
    })
}
