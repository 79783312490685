import { RouteContext } from '@/types/Router.type'
import { RouteName } from '@/vars/RouteName'

const cities = {
    marseille: ['marseille'],
    tokyo: ['tokyo'],
    singapour: ['singapour'],
    paris: ['paris', 'france'],
    madrid: ['madrid', 'iberia', 'espagne'],
    montreal: ['montreal', 'canada'],
    bruxelles: ['bruxelles', 'benelux'],
    dubai: ['dubai', 'moyen-orient'],
    'hong-kong': ['hong-kong', 'apac'],
    'san-francisco': ['san-francisco', 'west-coast'],
    londres: ['londres'],
    'new-york': ['new-york'],
    miami: ['miami'],
    shanghai: ['shanghai']
}

export async function redirectToNewLanding({ to, next, router }: RouteContext) {
    const slug = Object.entries(cities).find(([_key, values]) => values.includes(to.params?.citySlug.toString()))?.[0]

    if (slug && slug !== to.params?.citySlug.toString()) {
        return router.push({ name: RouteName.CITY_LANDING, params: { citySlug: slug } })
    }

    return next()
}

export function pathLandingClubCities(): string {
    return Object.values(cities)
        .map((city) => city.join('|'))
        .join('|') // path like new-york|londre|tokyo....
}
