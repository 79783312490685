<script setup lang="ts">
    import { computed } from 'vue'
    import { useI18n } from 'vue-i18n'
    import PublicClubHeader from '@/components/layouts/public/PublicClubHeader.vue'
    import ContainerTemplate from '@/components/templates/ContainerTemplate.vue'
    import FooterBottom from '@/components/layouts/network/footer-public/partials/FooterBottom.vue'
    import { SpaName } from '@/vars/SpaAttr'
    import { AcademyRouteName, ClubRouteName } from '@/vars/RouteName'

    defineProps<{
        asSlot?: boolean
    }>()

    const { t } = useI18n()

    const links = computed(() => {
        if (import.meta.env.VITE_SPA_RUNNING === SpaName.ACADEMY) {
            return [
                {
                    label: t('nav_academy.course'),
                    to: { name: AcademyRouteName.INTRODUCE_COURSE }
                }
            ]
        }

        return [
            {
                label: t('nav.membership'),
                to: { name: ClubRouteName.LANDING_MEMBERSHIP }
            },
            {
                label: t('nav.events'),
                to: { name: ClubRouteName.EVENTS_LISTING }
            },
            {
                label: t('nav.network'),
                to: { name: ClubRouteName.LANDING_COMMUNITY }
            },
            {
                label: t('nav.team'),
                to: { name: ClubRouteName.FF_TEAM }
            }
        ]
    })
</script>

<template>
    <div class="!bg-midnight relative">
        <PublicClubHeader :links="links" />
        <main>
            <slot v-if="asSlot" class="layout__content" />

            <RouterView v-else v-slot="{ Component: ViewComponent }" class="layout__router">
                <Transition name="page-">
                    <Component :is="ViewComponent" class="layout__content" />
                </Transition>
            </RouterView>
        </main>
        <ContainerTemplate public>
            <hr class="my-0 opacity-50" />
            <FooterBottom class="pb-10 pt-6 text-nuage" />
        </ContainerTemplate>
    </div>
</template>

<i18n lang="json">
{
    "fr": {
        "nav_academy": {
            "course": "Formation"
        },
        "nav": {
            "team": "Équipe"
        }
    }
}
</i18n>
