import 'regenerator-runtime'

import { createApp } from 'vue'
import VueNotification from '@kyvg/vue3-notification'
import {
    browserTracingIntegration,
    captureConsoleIntegration,
    init as initSentry,
    replayIntegration
} from '@sentry/vue'
import { VueQueryPlugin, VueQueryPluginOptions } from '@tanstack/vue-query'
import FloatingVue from 'floating-vue'
import gsap from 'gsap'
import ScrollToPlugin from 'gsap/ScrollToPlugin'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { createPinia } from 'pinia'
import { register as registerSwiper } from 'swiper/element/bundle'
import { ThemeTooltip } from '@/vars/ThemeAttr'
import App from './App.vue'
import i18n from './i18n'
import { placeholder } from './libs/directives/placeholder'
import { vRenderWhenChildren } from './libs/directives/renderWhenChildren'
import { vSafeHtml } from './libs/directives/safe-html'
import { whiteSpace } from './libs/directives/whiteSpace'
import { initGoogleAnalytics } from './libs/vendors/google/analytics/google-analytics'
import { initRouter } from './router'
import { QueryOptions } from './vars/QueryAttr'

import '@/libs/sass/_main.scss'
import 'normalize.css'
import './vee-validate'
import 'floating-vue/dist/style.css'
import '@fontsource-variable/material-symbols-rounded/wght.css'
import '@/libs/vendors/frenchfounders/common-components/common-components'
import '@/libs/vendors/frenchfounders/common-components/style.css'

const pinia = createPinia()

gsap.registerPlugin(ScrollTrigger)

registerSwiper()

gsap.registerPlugin(ScrollToPlugin)

// Obliger d'attendre la fin de l'initialisation du router sinon error Cannot access XXX before init
initRouter().then((router) => {
    const app = createApp(App)

    // Directives
    app.directive('placeholder', placeholder)
    app.directive('white-space', whiteSpace)
    app.directive('safe-html', vSafeHtml)
    app.directive('render-when-children', vRenderWhenChildren)

    // Plugins
    app.use(FloatingVue, {
        themes: {
            [ThemeTooltip.DEFAULT]: {
                triggers: ['hover'],
                autoHide: true,
                $resetCss: true
            },
            [ThemeTooltip.LIGHT]: {
                triggers: ['hover'],
                autoHide: true,
                $resetCss: true
            }
        }
    })
    app.use(VueQueryPlugin, {
        queryClientConfig: {
            defaultOptions: {
                queries: QueryOptions
            }
        }
    } as VueQueryPluginOptions)
    app.use(pinia)
    app.use(i18n)
    app.use(VueNotification)

    if (import.meta.env.VITE_APP_SENTRY_DSN) {
        initSentry({
            app,
            dsn: import.meta.env.VITE_APP_SENTRY_DSN,
            environment: import.meta.env.VITE_APP_ENVIRONMENT,
            ignoreErrors: [
                'Non-Error promise rejection captured',
                'Unable to preload CSS',
                "'text/html' is not a valid JavaScript MIME type",
                'Load failed', // Due to Safari API call while page url is changing
                'Request aborted',
                'Network Error',
                'Failed to fetch',
                '[Vue warn]',
                'Large Render Blocking Asset',
                'timeout exceeded'
            ],
            beforeSend(event, hint) {
                const error = hint.originalException as Error | undefined

                if (error?.message?.toLowerCase().includes('render blocking asset')) {
                    return null
                }

                return event
            },
            integrations: [
                browserTracingIntegration({ router }),
                replayIntegration(),
                captureConsoleIntegration({ levels: ['error'] })
            ],
            trackComponents: true,
            tracesSampleRate: 1.0,
            // Session Replay
            replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
            replaysOnErrorSampleRate: 0.1 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        })
    }

    if (import.meta.env.VITE_GOOGLE_GTM_TAG_ID) {
        initGoogleAnalytics(import.meta.env.VITE_GOOGLE_GTM_TAG_ID)
    }

    app.use(router)
    app.config.compilerOptions.isCustomElement = (tag: string) =>
        tag.startsWith('swiper') ||
        tag.startsWith('admin-member-meeting-widget') ||
        tag.startsWith('agreement-widget') ||
        tag.startsWith('admin-ai-widget')
    app.mount('#app')
})
