<script setup lang="ts">
    import { ref } from 'vue'
    import { useI18n } from 'vue-i18n'
    import { vIntersectionObserver } from '@vueuse/components'
    import gsap from 'gsap'
    import SpaRouterLink from '@/components/ui/navigations/SpaRouterLink.vue'
    import { useNavigationResolver } from '@/libs/compositions/useNavigationResolver'
    import { AboutRouteName, ClubRouteName, CompaniesRouteName, RouteName } from '@/vars/RouteName'

    type FooterLinks = {
        title: string
        links: {
            label: string
            to?: { name: string }
            href?: string
            action?: () => void
            target?: '_blank'
        }[]
    }

    const { t } = useI18n()
    const { redirectBounding } = useNavigationResolver()
    const hasTriggeredAnimation = ref(false)
    const company: FooterLinks = {
        title: t('company.title'),
        links: [
            {
                label: t('company.about'),
                to: { name: AboutRouteName.WEWEB_ABOUT }
            },
            {
                label: t('company.engagements'),
                to: { name: AboutRouteName.WEWEB_ENGAGEMENTS }
            },
            {
                label: t('company.join_us'),
                href: 'https://www.welcometothejungle.com/fr/companies/frenchfounders/jobs',
                target: '_blank'
            }
        ]
    }

    const services: FooterLinks = {
        title: t('services.title'),
        links: [
            {
                label: t('services.private_club'),
                href: redirectBounding({ name: ClubRouteName.HOME }).href
            },
            {
                label: t('services.b2b'),
                to: { name: AboutRouteName.WEWEB_BUSINESS_SOLUTIONS }
            },
            {
                label: t('services.invest'),
                to: { name: AboutRouteName.WEWEB_INVESTMENT }
            },

            {
                label: t('services.recrute'),
                to: { name: AboutRouteName.WEWEB_RECRUTE }
            },
            {
                label: t('services.talent_pool'),
                to: { name: AboutRouteName.WEWEB_LOOKING_JOB }
            }
        ]
    }

    const resources: FooterLinks = {
        title: t('resources.title'),
        links: [
            {
                label: t('resources.articles_videos'),
                to: { name: RouteName.RESOURCES_LIST }
            },
            {
                label: t('resources.podcasts'),
                to: { name: RouteName.RESOURCES_LIST_PODCASTS }
            },
            {
                label: t('resources.events'),
                to: { name: RouteName.EVENT_LIST }
            },
            {
                label: t('resources.business_directory'),
                to: { name: CompaniesRouteName.COMPANY_HOME }
            }
        ]
    }

    function onVisibilityChange([{ isIntersecting }]: IntersectionObserverEntry[]) {
        if (isIntersecting && !hasTriggeredAnimation.value) {
            hasTriggeredAnimation.value = true
            gsap.from('[data-animate-link]', {
                opacity: 0,
                x: 30,
                stagger: 0.1,
                ease: 'power2.quad'
            })
        }
    }
</script>

<template>
    <div v-intersection-observer="onVisibilityChange" class="footer-links">
        <div class="footer-links__spaces footer-links__column">
            <div class="footer-links__title mb-6">{{ company.title }}</div>
            <div v-for="link in company.links" :key="link.label" class="mt-4" data-animate-link>
                <SpaRouterLink v-if="link.to" :to="link.to">{{ link.label }}</SpaRouterLink>
                <a v-else-if="link.href" :href="link.href" :target="link.target">{{ link.label }}</a>
            </div>
        </div>
        <div class="footer-links__frenchfounders footer-links__column">
            <div class="footer-links__title mb-6">{{ services.title }}</div>
            <div v-for="link in services.links" :key="link.label" class="mt-4" data-animate-link>
                <SpaRouterLink v-if="link.to" :to="link.to">{{ link.label }}</SpaRouterLink>
                <a v-else-if="link.href" :href="link.href">{{ link.label }}</a>
            </div>
        </div>
        <div class="footer-links__socials footer-links__column">
            <div class="footer-links__title mb-6">{{ resources.title }}</div>
            <div v-for="link in resources.links" :key="link.label" class="mt-4" data-animate-link>
                <SpaRouterLink v-if="link.to" :to="link.to">{{ link.label }}</SpaRouterLink>
                <a v-else-if="link.href" :href="link.href">{{ link.label }}</a>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
    @import '@/libs/sass/vars';
    .footer-links {
        display: grid;
        gap: var(--scale-4);
        grid-template-areas:
            'spaces FF'
            '. FF'
            'socials FF';

        @media screen and (min-width: $breakpoints-lg) {
            display: flex;
            flex-flow: row wrap;
            gap: var(--scale-10);
        }

        &__spaces {
            grid-area: spaces;
        }

        &__frenchfounders {
            grid-area: FF;
            padding-left: var(--scale-4);
            @media screen and (min-width: $breakpoints-md) {
                width: 12.5em;
                padding-left: 0;
            }
        }

        &__socials {
            grid-area: socials;
        }

        &__column {
            width: initial;
            @media screen and (min-width: $breakpoints-md) {
                width: 12.5em;
            }
        }

        &__title {
            font-weight: bold;
        }
    }
</style>

<i18n lang="json">
{
    "fr": {
        "company": {
            "title": "Entreprise",
            "about": "À propos",
            "engagements": "Nos engagements",
            "join_us": "Nous rejoindre"
        },
        "services": {
            "title": "Services",
            "private_club": "Club privé",
            "b2b": "Espace B2B",
            "invest": "Investissement",
            "recrute": "Recrutement",
            "talent_pool": "Talent Pool"
        },
        "resources": {
            "title": "Ressources",
            "articles_videos": "Articles & Vidéos",
            "podcasts": "Podcasts",
            "events": "Événements",
            "business_directory": "Annuaire entreprises"
        },
        "legal": "Mentions légales",
        "cgu": "CGU",
        "cgu_entreprise": "CGU entreprises",
        "confidential": "Politique de confidentialité"
    },
    "en": {
        "company": {
            "title": "Company",
            "about": "About us",
            "engagements": "Our commitments",
            "join_us": "Join us"
        },
        "services": {
            "title": "Services",
            "private_club": "Private club",
            "b2b": "B2B platform",
            "invest": "Investment",
            "recrute": "Recruitment",
            "talent_pool": "Talent pool"
        },
        "resources": {
            "title": "Resources",
            "articles_videos": "Articles & Videos",
            "podcasts": "Podcasts",
            "events": "Events",
            "business_directory": "Business directory"
        },
        "legal": "Legal notices",
        "cgu": "Terms of Use",
        "cgu_entreprise": "Terms of Use for businesses",
        "confidential": "Privacy policy"
    }
}
</i18n>
