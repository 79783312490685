import 'regenerator-runtime'
import { createRouter, createWebHistory, RouteRecord } from 'vue-router'
import { checkTokenValidity, getAuthentifiedUser } from './libs/middlewares/auth'
import { RouteContext, RouteMiddleware } from './types/Router.type'

// Non-chunked views
import { SpaName } from './vars/SpaAttr'
import i18n from '@/i18n'
import { Lang } from '@/types/Lang.type'
import trackingUtm from '@/libs/middlewares/trackingUtm'

export const initRouter = async () => {
    const [appRoutes, clubRoutes, proRoutes, academyRoutes] = await Promise.all([
        import('./routes/app-routes'),
        import('./routes/club-routes'),
        import('./routes/pro-routes'),
        import('./routes/academy-routes')
    ])

    const router = createRouter({
        history: createWebHistory(),
        routes: determineRoutes(),
        scrollBehavior(to, from, savedPosition) {
            if (to.hash) {
                return {
                    el: to.hash,
                    behavior: 'smooth'
                }
            } else if (to.name !== from.name) {
                return {
                    top: 0,
                    behavior: 'smooth'
                }
            } else if (savedPosition) {
                return savedPosition
            } else {
                return {}
            }
        }
    })

    function determineRoutes() {
        switch (import.meta.env.VITE_SPA_RUNNING) {
            case SpaName.STORYBOOK:
                return []
            case SpaName.LENETWORK:
                return appRoutes.default as unknown as RouteRecord[]
            case SpaName.PRO:
                return proRoutes.default as unknown as RouteRecord[]
            case SpaName.LECLUB:
                return clubRoutes.default as unknown as RouteRecord[]
            case SpaName.ACADEMY:
                return academyRoutes.default as unknown as RouteRecord[]
            default:
                throw new Error(`Env: ${import.meta.env.VITE_SPA_RUNNING} not supported`)
        }
    }

    // Creates a `nextMiddleware()` function which not only
    // runs the default `next()` callback but also triggers
    // the subsequent Middleware function.
    function nextFactory(context: RouteContext, middleware: RouteMiddleware[], nextIndex: number) {
        const subsequentMiddleware = middleware[nextIndex]
        // If no subsequent Middleware exists,
        // the default `next()` callback is returned.
        if (!subsequentMiddleware) return context.next

        return async () => {
            // Run the default Vue Router `next()` callback first.
            // context.next(...parameters)
            // Then run the subsequent Middleware with a new
            // `nextMiddleware()` callback.
            const nextMiddleware = nextFactory(context, middleware, nextIndex + 1)
            await subsequentMiddleware({ ...context, next: nextMiddleware })
        }
    }

    router.beforeEach(trackingUtm)

    router.beforeEach(async (to, from, next) => {
        let middleware: RouteMiddleware[] = [checkTokenValidity, getAuthentifiedUser] // default middleware
        i18n.global.locale.value = Lang.FR

        if (to.meta.middleware) {
            const routeMiddlewares = Array.isArray(to.meta.middleware) ? to.meta.middleware : [to.meta.middleware]
            middleware = middleware.concat(routeMiddlewares)
        }

        if (to.meta?.context) {
            const context = typeof to.meta?.context === 'function' ? to.meta?.context(to) : to.meta?.context

            if (context === SpaName.LENETWORK) {
                i18n.global.locale.value = Lang.FR
            }
        }

        if (to.meta?.forceLang) {
            const forceLang = typeof to.meta?.forceLang === 'function' ? to.meta?.forceLang(to) : to.meta?.forceLang
            if (forceLang === Lang.FR || forceLang === Lang.EN) {
                i18n.global.locale.value = forceLang
            }
        }

        // Interval parce que "$crisp" est plus ou moins lent à être chargé en fonction de GTM
        if (to.meta?.hideCrisp) {
            const intervalId = setInterval(() => {
                window.$crisp?.push(['do', 'chat:hide'])
                clearInterval(intervalId)
            }, 100)
        } else {
            const intervalId = setInterval(() => {
                window.$crisp?.push(['do', 'chat:show'])
                clearInterval(intervalId)
            }, 100)
        }

        document.title = (to.meta?.title as string) || 'Frenchfounders'

        const context = {
            from,
            next,
            router,
            to
        }
        const nextMiddleware = nextFactory(context, middleware, 1)

        return await middleware[0]({ ...context, next: nextMiddleware })
    })

    return router
}
