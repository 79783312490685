import { useRouter } from 'vue-router'
import { getCurrentScope } from '@sentry/vue'
import { AxiosError, isAxiosError } from 'axios'
import jwtDecode from 'jwt-decode'
import { DateTime } from 'luxon'
import { defineStore } from 'pinia'
import i18n from '@/i18n'
import useNotification from '@/libs/compositions/useNotification'
import { FormRegister, User, UserTokenDecoded } from '@/types/Auth.type'
import { LoginForm } from '@/types/forms/auth-api/LoginForm.type'
import { AuthAttr, BusinessLineName, RoleName } from '@/vars/AuthAttr'
import { JWT_TOKEN_NAME, REFRESH_JWT_TOKEN_NAME } from '@/vars/JwtTokenAttr'
import { RouteName } from '@/vars/RouteName'
import { safeFrenchFoundersRedirect } from '@/vars/safeRedirect'
import { SpaName } from '@/vars/SpaAttr'
import { ToastType } from '@/vars/ToastAttr'
import AuthApi from '../api/Auth.api'
import useEkko from '../compositions/useEkko'
import SharedStorage from '../core/SharedStorage'
import { authenticateUserInGoogleAnalytics } from '../vendors/google/analytics/google-analytics'

/*
    Pour ne pas perdre la réactivité

    DO
    const authStore = useAuthStore()

    DONT
    const {isLoggedIn} = useAuthStore()
*/
type State = {
    adminOriginalToken: string
    authToken: string | null
    authRefreshToken: string | null
    authentifiedUser: User | null
}
export const useAuthStore = defineStore('auth', {
    state: (): State => {
        let authToken: string | null = import.meta.env.VITE_DEV_TOKEN || null

        if (!authToken) {
            try {
                authToken = SharedStorage.get(JWT_TOKEN_NAME)
            } catch (e) {
                console.error(e)
                authToken = null
                SharedStorage.delete(JWT_TOKEN_NAME)
            }
        }

        return {
            adminOriginalToken: SharedStorage.get(AuthAttr.ORIGINAL_TOKEN) || '',
            authToken,
            authRefreshToken: SharedStorage.get(REFRESH_JWT_TOKEN_NAME),
            authentifiedUser: null
        }
    },
    getters: {
        isLoggedIn(state): boolean {
            return !!(state.authToken && state.authentifiedUser)
        },
        decodedToken(state): UserTokenDecoded | undefined {
            if (!state.authToken) {
                return undefined
            }

            try {
                return jwtDecode(state.authToken)
            } catch {
                return undefined
            }
        },
        hasRole(): (role: RoleName) => boolean {
            return (role: RoleName) => {
                return this.decodedToken?.roles?.includes(role) || false
            }
        },
        isLostClub(): boolean {
            if (this.hasRole(RoleName.ADMIN) || this.hasRole(RoleName.SUPER_ADMIN)) {
                return false
            }

            return (
                (this.hasRole(RoleName.LECLUB_LOST) ||
                    this.hasRole(RoleName.LECLUB_EXPERT_LOST) ||
                    this.hasRole(RoleName.LECLUB_PARTNER_LOST)) &&
                !this.hasRole(RoleName.LECLUB)
            )
        },
        isOnSwitchTo(): boolean {
            return this.hasRole(RoleName.ROLE_PREVIOUS_ADMIN) || this.decodedToken?.original_member_id !== undefined
        },
        hasBusinessLine(): (businessLine: BusinessLineName) => boolean {
            return (businessLine: BusinessLineName) => {
                if (this.authentifiedUser?.business_lines) {
                    return this.authentifiedUser?.business_lines?.includes(businessLine) || false
                } else {
                    return this.decodedToken?.businessLines?.includes(businessLine) || false
                }
            }
        },
        hasMobileDevice(): () => boolean {
            return () => {
                return this.authentifiedUser?.hasMobileDevice || false
            }
        }
    },
    actions: {
        async getMe(): Promise<User | undefined> {
            const { connectToEkko } = useEkko()

            if (!this.authToken) {
                return undefined
            }

            const me = await AuthApi.getMe()
            this.setMe(me)

            if (['/login', '/register'].includes(window.location.pathname)) {
                try {
                    connectToEkko(me.idff, me.ekko_access_token)
                } catch (e) {
                    // Ignore l'erreur car elle peut être liée au fait qu'on se connecte au même moment où on change de page (a cause de la redirection)
                }
            } else {
                connectToEkko(me.idff, me.ekko_access_token)
            }

            return me
        },

        async switchTo(email: string) {
            const router = useRouter()

            // Pour ignorer erreur de CORS
            try {
                await AuthApi.switchTo(email)
            } catch (e) {
                console.error(e)
            }

            const { token } = await AuthApi.getAuthToken()
            this.updateTokens(token)
            const switchedUser = await this.getMe()

            if (switchedUser?.business_lines?.includes(BusinessLineName.LECLUB)) {
                window.location.replace(import.meta.env.VITE_PRIVATE_BASE_URL + '/member/me')
            } else {
                router.replace({ name: RouteName.MEMBER_ME })
            }
        },

        async exitSwitchToUser() {
            try {
                await AuthApi.exitSwitchTo()
            } catch {
                // ignore silencieusement la redirection
            }
            const { token } = await AuthApi.getAuthToken()
            this.updateTokens(token)

            if (import.meta.env.VITE_ENABLE_FORCE_LOGIN === 'true') {
                await AuthApi.forceLogin()
            }
            await this.getMe()
            window.location.replace(import.meta.env.VITE_PRIVATE_BASE_URL + '/member/me')
        },

        setMe(me: User | null): void {
            if (me) {
                this.authentifiedUser = me
                authenticateUserInGoogleAnalytics(me)
            }
            getCurrentScope().setUser(me)
        },

        async refreshToken(): Promise<string | null> {
            if (!this.authToken || !this.authRefreshToken) {
                this.logout()

                return null
            }

            const { token } = await AuthApi.refreshToken(this.authToken, this.authRefreshToken)
            this.updateTokens(token)

            return token
        },
        /**
         * Cette fonction a un double usage, elle peut rediriger vers la SPA 1 si le premier paramètre est une string
         * sinon elle fait un vrai "login".
         * @param options.silent - Si l'option est activée, aucun toast/message d'erreur n'est déclenché
         */
        async login(loginFormOrRedirectAfterLogin: string | null | LoginForm, options?: { silent: boolean }) {
            const { toast } = useNotification()

            if (typeof loginFormOrRedirectAfterLogin === 'string') {
                // Redirect to login on SPA 1
                const redirectAfterLogin = loginFormOrRedirectAfterLogin
                const spa = import.meta.env.VITE_SPA_RUNNING
                const context = spa === SpaName.LENETWORK ? 'community' : 'pro'
                const redirectUrl = redirectAfterLogin ? `&redirectUrl=${redirectAfterLogin}` : ''

                return (window.location.href = `${
                    import.meta.env.VITE_APP_BASE_URL
                }/login?context=${context}${redirectUrl}`)
            } else if (loginFormOrRedirectAfterLogin) {
                // Real login on SPA 2
                const loginForm = loginFormOrRedirectAfterLogin

                try {
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    const loginResponse = await AuthApi.login(loginForm)
                    this.updateTokens(loginResponse.token, loginResponse.refresh_token)
                    await this.getMe()

                    if (import.meta.env.VITE_ENABLE_FORCE_LOGIN === 'true') {
                        await AuthApi.forceLogin()
                    }

                    return loginResponse
                } catch (e) {
                    if (isAxiosError(e) && !options?.silent) {
                        const error = e as AxiosError

                        if (error?.response?.status === 401) {
                            toast({
                                type: ToastType.ERROR,
                                title: i18n.global.t('error.login_failed.title'),
                                text: i18n.global.t('error.login_failed.text')
                            })
                        } else {
                            console.error(e)
                            toast({
                                type: ToastType.ERROR,
                                title: i18n.global.t('error.generic.title'),
                                text: i18n.global.t('error.generic.text')
                            })
                        }
                    }
                    throw e
                }
            }
        },

        async register(form: FormRegister) {
            const registerResult = await AuthApi.register(form)
            this.updateTokens(registerResult.meta.jwt.token, registerResult.meta.jwt.refreshToken)

            return registerResult
        },

        redirectToRegister(redirectAfterRegister: string) {
            return (window.location.href = `${
                import.meta.env.VITE_PRIVATE_BASE_URL
            }/register?context=community&redirectUrl=${redirectAfterRegister}`)
        },

        async loginRoom(form: { username: string; password: string }) {
            const data = await AuthApi.login({ email: form.username, password: form.password })
            this.updateTokens(data.token, data.refresh_token)
            await this.getMe()

            if (import.meta.env.VITE_ENABLE_FORCE_LOGIN === 'true') {
                await AuthApi.forceLogin()
            }

            return data
        },

        async logout(redirectUrl?: string) {
            try {
                await AuthApi.forceLogout()
            } catch (e) {
                if (isAxiosError(e)) {
                    const error = e as AxiosError

                    if (!error.response || ![302, 301].includes(error.response.status)) {
                        console.error(error)
                    }
                }
            }
            SharedStorage.delete(AuthAttr.ORIGINAL_TOKEN)
            SharedStorage.delete(JWT_TOKEN_NAME)
            SharedStorage.delete(REFRESH_JWT_TOKEN_NAME)

            this.adminOriginalToken = ''
            this.authToken = ''
            this.authRefreshToken = ''
            this.authentifiedUser = null
            safeFrenchFoundersRedirect(redirectUrl || import.meta.env.VITE_APP_BASE_URL)
        },

        updateTokens(token: string, refreshToken?: string) {
            this.authToken = token
            const cookieDuration = DateTime.now().plus({ year: 1 }).toJSDate()
            SharedStorage.set(JWT_TOKEN_NAME, token, cookieDuration)

            if (refreshToken) {
                this.authRefreshToken = refreshToken
                SharedStorage.set(REFRESH_JWT_TOKEN_NAME, refreshToken, cookieDuration)
            }
        }
    }
})
