import { useQuery } from '@tanstack/vue-query'
import { AxiosError } from 'axios'
import MemberApi from '@/libs/api/Member.api'
import { useAuthStore } from '@/libs/stores/auth.store'
import { QueryName } from '@/vars/QueryAttr'

export default function useGettingStartedTodoQuery(opts?: {
    enabled?: boolean
    staleTime?: number
    refetchOnMount?: boolean
}) {
    const authStore = useAuthStore()

    return useQuery<unknown, AxiosError, Awaited<ReturnType<typeof MemberApi.getStartedTodo>>>({
        queryKey: [QueryName.GETTING_STARTED, import.meta.env.VITE_SPA_RUNNING],
        queryFn: () => MemberApi.getStartedTodo(),
        enabled: () => {
            return authStore.isLoggedIn
        },
        ...opts
    })
}
