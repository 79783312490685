<script setup lang="ts">
    import { computed, ref, watch } from 'vue'
    import { useI18n } from 'vue-i18n'
    import { useRoute } from 'vue-router'
    import { ParticlesComponent } from 'vue3-particles'
    import { useQuery } from '@tanstack/vue-query'
    import type { Engine } from 'tsparticles-engine'
    import { loadSlim } from 'tsparticles-slim'
    import ModalWhatsNew from '@/components/partials/common/WhatsNew/ModalWhatsNew.vue'
    import MaterialIcon from '@/components/ui/icons/MaterialIcon.vue'
    import CardBody from '@/components/ui/wrappers/cards/CardBody.vue'
    import CardWrapper from '@/components/ui/wrappers/cards/CardWrapper.vue'
    import ConfigurationApi from '@/libs/api/Configuration.api'
    import { useAuthStore } from '@/libs/stores/auth.store'
    import { useSegment } from '@/libs/vendors/segment/segment'
    import { whatsNewParticlesConfig } from '@/vars/particles'
    import { QueryName } from '@/vars/QueryAttr'
    import { SpaName } from '@/vars/SpaAttr'
    import { StorageKey } from '@/vars/StorageAttr'

    const props = defineProps<{
        scope: SpaName
    }>()

    const { t } = useI18n()
    const { analytics } = useSegment()
    const route = useRoute()
    const authStore = useAuthStore()

    const cardEl = ref<HTMLDivElement>()
    const isProductUpdateOpen = ref(false)
    const isFloatingCardVisible = ref(false)

    const productUpdateStorageKey = StorageKey.PRODUCT_UPDATE_LAST_DATE + '_' + props.scope

    const { data: productUpdates } = useQuery({
        queryKey: [QueryName.PRODUCT_UPDATES, props.scope],
        queryFn: () => ConfigurationApi.getLastProductUpdates()
    })

    const newProductUpdateList = computed(() => {
        const lastSeenProductDate = window.localStorage.getItem(productUpdateStorageKey)

        if (!productUpdates.value?.data) {
            return []
        }

        if (!lastSeenProductDate) {
            return productUpdates.value.data
        }

        return productUpdates.value.data.filter(
            (productUpdate) => new Date(productUpdate.date).getTime() > new Date(lastSeenProductDate).getTime()
        )
    })

    watch(
        newProductUpdateList,
        () => {
            if (newProductUpdateList.value?.length) {
                isFloatingCardVisible.value = true
            }
        },
        { immediate: true }
    )

    const onShowProductUpdate = () => {
        isProductUpdateOpen.value = true
        isFloatingCardVisible.value = false

        analytics.track('community:whats_new')

        window.localStorage.setItem(productUpdateStorageKey, new Date().toISOString())
    }

    const particlesInit = async (engine: Engine) => {
        await loadSlim(engine)
    }
</script>

<template>
    <div v-if="newProductUpdateList.length && !route.meta.hideWhatsNew && authStore.isLoggedIn" class="whats-new">
        <CardWrapper
            v-if="isFloatingCardVisible"
            id="whats-new"
            ref="cardEl"
            class="whats-new__card"
            data-testid="whats-new"
            @click="onShowProductUpdate"
        >
            <div class="whats-new__particles-wrapper">
                <ParticlesComponent
                    id="tsparticles"
                    :options="whatsNewParticlesConfig"
                    :particlesInit="particlesInit"
                />
            </div>
            <CardBody class="whats-new__card-body">
                <h6 class="inline-flex items-center gap-4 font-medium">
                    <span class="text-sm">{{ t('whats_new') }}</span>
                    <MaterialIcon class="title-4" name="arrow_forward" />
                </h6>
                <p class="text-xs text-gray-3">{{ t('whats_new_text') }}</p>
            </CardBody>
        </CardWrapper>
        <ModalWhatsNew v-model="isProductUpdateOpen" :product-updates="productUpdates?.data || []" />
    </div>
</template>

<style scoped lang="scss">
    .whats-new {
        position: fixed;
        bottom: var(--scale-8r);
        left: var(--scale-6r);
        z-index: 100;
        cursor: pointer;

        &__card {
            transition: all 0.2s ease-out;

            &:hover {
                scale: 1.02;
            }
        }

        &__particles-wrapper {
            $offset: calc(var(--scale-6) * -1);
            position: absolute;
            top: $offset;
            left: $offset;
            right: $offset;
            bottom: $offset;
            z-index: -1;
        }

        &__card-body {
            padding: var(--scale-4r);
        }
    }
</style>

<i18n lang="json">
{
    "fr": {
        "whats_new": "Découvrez les nouveautés",
        "whats_new_text": "Voir la liste des changements"
    },
    "en": {
        "whats_new": "Discover the new features",
        "whats_new_text": "See the list of changes"
    }
}
</i18n>
