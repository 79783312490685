<script setup lang="ts">
    import { ThemeName } from '@/vars/ThemeAttr'
    import { computed, provide } from 'vue'

    /**
     * @joconde
     */

    const props = withDefaults(
        defineProps<{
            theme?: ThemeName
            tag?: string
            transparent?: boolean
        }>(),
        {
            tag: 'div',
            theme: ThemeName.DEFAULT
        }
    )

    /**
     * Quand Mondrian est activé, tous les themes sont surchargés
     **/
    const appliedTheme = computed(() => {
        return import.meta.env.VITE_APP_THEME === 'mondrian' ? ThemeName.MONDRIAN : props.theme
        // return props.theme
    })

    provide('themeName', appliedTheme.value)
</script>

<template>
    <Component
        :is="tag"
        class="theme"
        :class="[
            `theme--${appliedTheme}`,
            {
                [`theme--transparent`]: transparent
            }
        ]"
    >
        <slot></slot>
    </Component>
</template>

<style lang="scss">
    @import '@/libs/sass/mixins/theme.scss';
    @import '@/libs/sass/themes/mondrian/main.scss';

    body,
    .theme {
        &:not(.theme--transparent) {
            background: var(--theme-background-color);
            color: var(--theme-text-color);
        }

        // ------------------ <Mondrian Palette> ------------------
        @include mondrianVariantColor('flagship', #559bfb);
        @include mondrianVariantColor('royale', #0067dc);
        @include mondrianVariantColor('legacy', #022859);
        @include mondrianVariantColor('nuage', #fff);
        @include mondrianVariantColor('ace', #df2b4d);
        @include mondrianVariantColor('go', #09804a);
        @include mondrianVariantColor('ray', #ffc000);
        @include mondrianVariantColor('error', #f93b59);
        @include mondrianVariantColor('ok', #00a374);
        @include mondrianVariantColor('midnight', #011633);

        // Override mondrian variant
        --theme-color-go--10: #e6f2ed;

        --theme-color-gray--01: #333333;
        --theme-color-gray--02: #4f4f4f;
        --theme-color-gray--03: #828282;
        --theme-color-gray--04: #bdbdbd;
        --theme-color-gray--05: #e0e0e0;
        --theme-color-gray--06: #f2f2f2;
        --theme-color-gray--07: #fafafa;

        // ----------------- </ Mondrian Palette> -----------------

        // background colors
        --theme-background-color: white;
        --theme-background-color--rgb: 255, 255, 255;
        --theme-background-color--important: #fcfcff;
        --theme-background-color--important--rgb: 252, 252, 255;
        --theme-slider-background--rgb: var(--theme-background-color--rgb);
        --theme-background-color--dropdown: #ffffff;
        --theme-background-color--header: #ffffff;
        --theme-background-color--header-pill: rgba(255, 255, 255, 10);
        --theme-background-color--gradient-14: linear-gradient(
            90deg,
            rgba(111, 90, 241, 0.14) 6%,
            rgba(106, 146, 249, 0.14) 94%
        );
        --theme-background-color--blue--flagship--rgb: 85, 155, 251;
        --theme-background-color--blue--royale--rgb: 0, 103, 220;
        --theme-background-color--blue--legacy--rgb: 2, 40, 89;
        --theme-background-color--red--ace--rgb: 223, 43, 77;
        --theme-background-color--green--go--rgb: 9, 128, 74;
        --theme-background-color--orange--solid--rgb: 249, 116, 101;
        --theme-text-light: #91aabf;
        --theme-text-color: #102333;
        --theme-text-color--50: #10233350;
        --theme-text-color--rgb: 16, 35, 51;
        --theme-text-color--secondary: #bdbdbd;
        --theme-text-color--third: #828282;
        --theme-text-color--dropdown: #102333;
        --theme-text-color--header: #102333;
        --theme-placeholder-color: #f1f1f1;
        --theme-text-color-hover: #4f4f4f;

        // status colors
        --theme-success-color: #00bb9c;
        --theme-success-color--light: #e2f9f0;
        --theme-success-color--rgb: 0, 187, 156;
        --theme-success-color--light--rgb: 226, 249, 240;
        --theme-info-color: #093d58;
        --theme-warning-color: yellow;
        --theme-error-color: #ce4e40;
        --theme-error-color--light: #ffe2e0;
        --theme-warn-color: #ff9444;
        --theme-warn-color--light: #ffe0c9;

        // badge color
        --theme-badge-default-color: #64748b;
        --theme-badge-default-bg: #f1f5f9;
        --theme-badge-waiting-color: #525be5;
        --theme-badge-waiting-bg: #e0e7fd;

        // misc colors
        --theme-border-color: #e0e0e0;
        --theme-shadow-color: rgba(0, 0, 0, 0.2);
        --theme-shadow-color--header: rgba(0, 0, 0, 0.1);
        --theme-border-color--header-pill: var(--theme-border-color);

        // button colors
        --theme-button-color: var(--theme-text-color);
        --theme-button-text-color: var(--theme-background-color);

        // themed colors
        --theme-primary-color: #6a92f9;
        --theme-primary-gradient: linear-gradient(266.12deg, #6a92f9 6.34%, #6f5af1 93.66%);
        --theme-primary-text-color: white;

        &--mondrian {
            @include mondrianmain;
            --theme-color-text: var(--theme-color-legacy);
            --theme-text-color: var(--theme-color-legacy); /* LEGACY */
            --theme-text-color--rgb: var(--theme-color-legacy--rgb); /* LEGACY */
            --theme-color-text--light: var(--theme-color-nuage);
            --theme-primary-gradient: var(--theme-color-royale);
            --theme-primary-color: var(--theme-color-royale);
            --theme-background-color: var(--theme-color-nuage);
            --theme-background-color--important: var(--theme-color-nuage);
            --theme-bs-color-bg-alternative: var(--theme-color-legacy);
            --theme-bs-color-bg-light: var(--theme-color-flagship--10);
            --theme-color-header-background: var(--theme-color-legacy);
            --theme-color-footer-background: var(--theme-color-legacy);
            --theme-border-radius--card: 0.5em;

            --font: 'DM Sans', sans-serif;
            font-family: var(--font);
            // line-height: 1;
        }

        &--leclub,
        &--premium {
            // background colors
            --theme-background-color--important: #eef6fc;
            --theme-background-color--important--rgb: 238, 246, 252;
            --theme-background-color--header: #102333;
            --theme-background-color--header-pill: rgba(255, 255, 255, 0.1);
            --theme-background-color--gradient-14: linear-gradient(
                91.59deg,
                rgba(255, 148, 68, 0.14) -28.02%,
                rgba(255, 100, 140, 0.14) 106.48%
            );

            // text colors
            --theme-text-color--secondary: #91aabf;
            --theme-text-color--header: #ffffff;

            // themed colors
            --theme-primary-color: #f97465;
            --theme-primary-gradient: linear-gradient(90deg, #ff9444 6.34%, #ff648c 93.66%);

            // misc colors
            --theme-border-color--header-pill: transparent;
        }

        &--pro {
            --theme-primary-color: #102333;
            --theme-primary-gradient: var(--theme-primary-color);
            --theme-button-color: #e7e9eb;
            --theme-success-color: #1fccad;
            --theme-button-text-color: var(--theme-text-color);
        }

        &--dark,
        &--premium {
            // background colors
            --theme-background-color: #102333;
            --theme-background-color--rgb: 16, 35, 51;
            --theme-background-color--important: #18364e;
            --theme-background-color--important--rgb: 24, 54, 78;
            --theme-background-color--header: #102333;
            --theme-background-color--header-pill: rgba(255, 255, 255, 0.1);

            // text colors
            --theme-text-color: #ffffff;
            --theme-text-color--50: #ffffff50;
            --theme-text-color--header: #ffffff;

            // misc colors
            --theme-shadow-color: rgba(255, 255, 255, 0.25);
            --theme-border-color--header-pill: transparent;
        }
    }
</style>
