import { RouteRecordRaw } from 'vue-router'
import { AcademyRouteName } from '@/vars/RouteName'
import PublicClubLayout from '@/components/layouts/public/PublicClubLayout.vue'
import EmptyLayout from '@/components/layouts/EmptyLayout.vue'

const routes: RouteRecordRaw[] = [
    {
        path: '/',
        name: AcademyRouteName.HOME,
        component: () => import('@/views/academy/home/AcademyHome.vue'),
        meta: {
            layout: PublicClubLayout
        }
    },
    {
        path: '/course',
        name: AcademyRouteName.INTRODUCE_COURSE,
        component: () => import('@/views/academy/course/CourseIntroduction.vue'),
        meta: {
            layout: PublicClubLayout
        }
    },
    {
        path: '/pre-register',
        name: AcademyRouteName.PRE_REGISTER,
        component: () => import('@/views/academy/pre-register/AcademyPreRegister.vue'),
        meta: {
            layout: EmptyLayout,
            hideWhatsNew: true
        }
    }
]

export default routes
