import { RouteRecordRaw } from 'vue-router'
import EmptyLayout from '@/components/layouts/EmptyLayout.vue'
import ApplyLayout from '@/components/layouts/leclub/ApplyLayout.vue'
import DefaultClubLayout from '@/components/layouts/leclub/DefaultClubLayout.vue'
import { useNavigationResolver } from '@/libs/compositions/useNavigationResolver'
import { paywallCheck, requiredAuthentification, requiredRoleLeClub } from '@/libs/middlewares/auth'
import { redirectToPodcastIfSlugHasPodcast } from '@/libs/middlewares/resources'
import { Lang } from '@/types/Lang.type'
import { ClubRouteName } from '@/vars/RouteName'
import { SpaName } from '@/vars/SpaAttr'
import { Step } from '@/views/onboardings/club-onboarding/step-order'
import aboutRoutes from './common/about-routes'
import authRoutes from './common/auth-routes'
import companiesRoutes from './common/companies-routes'
import eventsRoutes from './common/events-routes'
import inboxRoutes from './common/inbox-routes'
import letterManagementRoutes from './common/letter-management-routes'
import whatsappRoutes from './common/whatsapp-routes'

const routes: RouteRecordRaw[] = [
    ...companiesRoutes,
    ...whatsappRoutes,
    ...eventsRoutes,
    ...authRoutes,
    ...inboxRoutes,
    ...aboutRoutes,
    ...letterManagementRoutes,
    {
        name: ClubRouteName.EVENTS_LISTING,
        path: '/events',
        component: () => import('@/views/club/events/listing/EventsListingPage.vue'),
        meta: {
            middleware: [requiredAuthentification, requiredRoleLeClub],
            hasGridBackground: true,
            gridBackgroundHeight: '42em'
        }
    },
    {
        path: '/articles',
        name: ClubRouteName.ARTICLE_LIST,
        component: () => import('@/views/club/resources/articles/ArticlesList.vue'),
        meta: {
            middleware: [requiredAuthentification, requiredRoleLeClub],
            hasGridBackground: true
        }
    },
    {
        path: '/article/:id',
        name: ClubRouteName.ARTICLE_SINGLE,
        component: () => import('@/views/resources/resources-single/ResourceSingle.vue'),
        meta: {
            middleware: [requiredAuthentification, requiredRoleLeClub]
        }
    },
    {
        path: '/videos',
        name: ClubRouteName.VIDEO_LIST,
        component: () => import('@/views/club/resources/videos/video-list/VideoList.vue'),
        meta: {
            middleware: [requiredAuthentification, requiredRoleLeClub],
            hasGridBackground: true
        }
    },
    {
        path: '/video/:slug',
        name: ClubRouteName.VIDEO_SINGLE,
        component: () => import('@/views/resources/resources-single/ResourceSingle.vue'),
        meta: {
            middleware: [requiredAuthentification, requiredRoleLeClub, redirectToPodcastIfSlugHasPodcast]
        }
    },
    {
        path: '/podcasts',
        name: ClubRouteName.PODCAST_LIST,
        component: () => import('@/views/club/resources/podcasts/PodcastList.vue'),
        meta: {
            middleware: [requiredAuthentification, requiredRoleLeClub],
            hasGridBackground: true
        }
    },
    {
        path: '/podcast/:slug',
        name: ClubRouteName.PODCAST_SINGLE,
        component: () => import('@/views/resources/resources-single/ResourceSingle.vue'),
        meta: {
            middleware: [requiredAuthentification, requiredRoleLeClub]
        }
    },
    {
        path: '/',
        name: ClubRouteName.HOME,
        component: () => import('@/views/club/home/HomePage.vue'),
        meta: {
            middleware: [requiredAuthentification, requiredRoleLeClub],
            hasGridBackground: true
        }
    },
    {
        path: '/create-relation/:firstMemberId?/:secondMemberId?',
        name: ClubRouteName.CREATE_MER,
        meta: {
            layout: DefaultClubLayout,
            middleware: [requiredAuthentification, requiredRoleLeClub],
            forceLang: Lang.FR
        },
        component: () => import('@/views/club/mer/CreateRelationPage.vue')
    },
    {
        name: ClubRouteName.WORKSPACES,
        path: '/offices',
        component: () => import('@/views/club/workspaces/list/WorkspaceList.vue'),
        meta: {
            middleware: [requiredAuthentification, requiredRoleLeClub]
        }
    },
    {
        name: ClubRouteName.FF_TEAM,
        path: '/team',
        component: () => import('@/views/club/team/OurTeamPage.vue'),
        meta: {
            middleware: [requiredAuthentification, requiredRoleLeClub]
        }
    },
    {
        name: ClubRouteName.TRIP_PLANNER_LIST,
        path: '/trips',
        component: () => import('@/views/club/trip-planner/trip-listing/TripListingPage.vue'),
        meta: {
            middleware: [requiredAuthentification, requiredRoleLeClub]
        }
    },
    {
        name: ClubRouteName.BENEFITS,
        path: '/benefits/:category?',
        component: () => import('@/views/club/benefits/BenefitListPage.vue'),
        meta: {
            middleware: [requiredAuthentification, requiredRoleLeClub]
        }
    },
    {
        name: ClubRouteName.TRIP_PLANNER_SINGLE,
        path: '/trips/:trip',
        component: () => import('@/views/club/trip-planner/trip-details/TripDetailsPage.vue'),
        meta: {
            middleware: [requiredAuthentification, requiredRoleLeClub]
        }
    },
    {
        name: ClubRouteName.TRIP_PLANNER_ADD,
        path: '/trips/add',
        component: () => import('@/views/club/trip-planner/add-trip/AddTripPage.vue'),
        meta: {
            middleware: [requiredAuthentification, requiredRoleLeClub],
            hasGridBackground: true,
            gridBackgroundHeight: '35em'
        }
    },
    {
        name: ClubRouteName.BENEFITS_SINGLE,
        path: '/benefits/details/:benefit',
        component: () => import('@/views/club/benefits/partials/BenefitDetails.vue'),
        meta: {
            middleware: [requiredAuthentification, requiredRoleLeClub]
        }
    },
    {
        name: ClubRouteName.HUBS,
        path: '/hubs/:hub',
        component: () => import(/* webpackChunkName: 'hubs' */ '@/views/club/hubs/HubSingle.vue'),
        meta: {
            middleware: [requiredAuthentification, requiredRoleLeClub],
            layoutProps: {
                extended: 'sm'
            }
        },
        children: [
            {
                name: ClubRouteName.HUB_SINGLE,
                path: '',
                component: () => import(/* webpackChunkName: 'hubs' */ '@/views/club/hubs/partials/HubOverview.vue'),
                meta: {
                    layoutProps: {
                        extended: 'sm'
                    }
                }
            },
            {
                name: ClubRouteName.HUB_SINGLE_CHAT,
                path: 'discussions',
                component: () => import(/* webpackChunkName: 'hubs' */ '@/views/club/hubs/partials/HubChatRoom.vue'),
                meta: {
                    layoutProps: {
                        extended: 'sm'
                    }
                }
            },
            {
                name: ClubRouteName.HUB_SINGLE_MEMBERS,
                path: 'members',
                component: () => import(/* webpackChunkName: 'hubs' */ '@/views/club/hubs/partials/HubMembersList.vue'),
                meta: {
                    layoutProps: {
                        extended: 'sm'
                    }
                }
            },
            {
                name: ClubRouteName.HUB_SINGLE_EVENTS,
                path: 'events',
                component: () => import(/* webpackChunkName: 'hubs' */ '@/views/club/hubs/partials/HubEvents.vue'),
                meta: {
                    layoutProps: {
                        extended: 'sm'
                    }
                }
            }
        ]
    },
    {
        name: ClubRouteName.EVENTS_LIVE_AND_REPLAY,
        path: '/events/live-and-replay',
        component: () => import('@/views/events/replays/replays-list/LiveAndReplayListPage.vue'),
        meta: {
            middleware: [requiredAuthentification, requiredRoleLeClub]
        }
    },
    {
        name: ClubRouteName.EVENT_REPLAY,
        path: '/event/:id/:eventSlug/replay',
        component: () => import('@/views/events/replays/replays-details/ReplayDetailsPage.vue'),
        meta: {
            middleware: [requiredAuthentification, requiredRoleLeClub]
        }
    },
    {
        name: ClubRouteName.LISTING_MER,
        path: '/member',
        component: () => import('@/views/club/listing-mer/ListingRelationsPage.vue'),
        meta: {
            middleware: [requiredAuthentification, requiredRoleLeClub],
            hasGridBackground: true
        },
        redirect: () => ({
            name: ClubRouteName.LISTING_MER_RECEIVED
        }),
        children: [
            {
                path: '',
                name: ClubRouteName.LISTING_MER_RECEIVED,
                component: () => import('@/views/club/listing-mer/partials/ListingRelationsReceived.vue')
            },
            {
                path: 'sent',
                name: ClubRouteName.LISTING_MER_SENT,
                component: () => import('@/views/club/listing-mer/partials/ListingRelationsSent.vue')
            },
            {
                path: 'scheduled',
                name: ClubRouteName.LISTING_MER_SCHEDULED,
                component: () => import('@/views/club/listing-mer/partials/ListingRelationsScheduled.vue')
            }
        ]
    },
    {
        name: ClubRouteName.ONBOARDING_MEMBER,
        path: '/member/onboarding/',
        component: () => import('@/views/onboardings/club-onboarding/ClubOnboarding.vue'),
        meta: {
            layout: EmptyLayout,
            middleware: [requiredAuthentification, requiredRoleLeClub],
            hideCrisp: true,
            hideWhatsNew: true
        },
        children: [
            {
                path: 'choose-password',
                name: ClubRouteName.ONBOARDING_MEMBER_PASSWORD,
                component: () => import('@/views/onboardings/club-onboarding/steps/StepPassword.vue'),
                meta: {
                    stepName: 'choose-password' satisfies Step
                }
            },
            {
                path: 'about-you',
                name: ClubRouteName.ONBOARDING_MEMBER_ABOUT_YOU,
                component: () => import('@/views/onboardings/club-onboarding/steps/StepAboutYou.vue'),
                meta: {
                    stepName: 'about-you' satisfies Step
                }
            },
            {
                path: 'company-experience',
                name: ClubRouteName.ONBOARDING_MEMBER_COMPANY,
                component: () => import('@/views/onboardings/club-onboarding/steps/StepCompanyExperience.vue'),
                meta: {
                    stepName: 'company-experience' satisfies Step
                }
            },
            {
                name: ClubRouteName.ONBOARDING_MEMBER_BUSINESS,
                path: 'business',
                component: () => import('@/views/onboardings/club-onboarding/steps/StepBusinessIssues.vue'),
                meta: {
                    stepName: 'business-issues' satisfies Step
                }
            },
            {
                path: 'interests',
                name: ClubRouteName.ONBOARDING_MEMBER_INTERESTS,
                component: () => import('@/views/onboardings/club-onboarding/steps/StepInterest.vue'),
                meta: {
                    stepName: 'interests' satisfies Step
                }
            },
            {
                name: ClubRouteName.ONBOARDING_MEMBER_GOALS,
                path: 'goals',
                component: () => import('@/views/onboardings/club-onboarding/steps/StepGoals.vue'),
                meta: {
                    stepName: 'goals' satisfies Step
                }
            },
            {
                name: ClubRouteName.ONBOARDING_MEMBER_PREFERENCES,
                path: 'preferences',
                component: () => import('@/views/onboardings/club-onboarding/steps/StepPreferences.vue'),
                meta: {
                    stepName: 'preferences' satisfies Step
                }
            },
            {
                name: ClubRouteName.ONBOARDING_MEMBER_FINISHED,
                path: 'finished',
                component: () => import('@/views/onboardings/club-onboarding/steps/StepFinish.vue'),
                meta: {
                    stepName: 'finished' satisfies Step
                }
            }
        ]
    },
    {
        name: ClubRouteName.CLUB,
        path: '/clubs/:club',
        component: () => import('@/views/club/clubs/ClubSingle.vue'),
        meta: {
            middleware: [requiredAuthentification, requiredRoleLeClub],
            layoutProps: {
                extended: 'sm'
            }
        },
        children: [
            {
                name: ClubRouteName.CLUB_SINGLE,
                path: '',
                component: () => import('@/views/club/clubs/tabs/ClubHome.vue'),
                meta: {
                    layoutProps: {
                        extended: 'sm'
                    }
                }
            },
            {
                name: ClubRouteName.CLUB_SINGLE_CHAT,
                path: 'discussions',
                component: () => import('@/views/club/clubs/tabs/ClubChatRoom.vue'),
                meta: {
                    layoutProps: {
                        extended: 'sm'
                    }
                }
            },
            {
                name: ClubRouteName.CLUB_SINGLE_WHATSAPP,
                path: 'whatsapp',
                component: () => import('@/views/club/clubs/tabs/ClubWhatsapp.vue'),
                meta: {
                    layoutProps: {
                        extended: 'sm'
                    }
                }
            },
            {
                name: ClubRouteName.CLUB_SINGLE_MEMBERS,
                path: 'members',
                component: () => import('@/views/club/clubs/tabs/ClubMembersList.vue'),
                meta: {
                    layoutProps: {
                        extended: 'sm'
                    }
                }
            },
            {
                name: ClubRouteName.CLUB_SINGLE_BOARD_MEMBERS,
                path: 'board',
                component: () => import('@/views/club/clubs/tabs/board/ClubBoard.vue'),
                meta: {
                    layoutProps: {
                        extended: 'sm'
                    }
                }
            },
            {
                name: ClubRouteName.CLUB_SINGLE_PROJECT,
                path: 'projects',
                component: () => import('@/views/club/clubs/tabs/ClubProjectList.vue'),
                meta: {
                    layoutProps: {
                        extended: 'sm'
                    }
                }
            },
            {
                name: ClubRouteName.CLUB_SINGLE_EVENTS,
                path: 'events',
                component: () => import('@/views/club/clubs/tabs/ClubEvents.vue'),
                meta: {
                    layoutProps: {
                        extended: 'sm'
                    }
                }
            },
            {
                name: ClubRouteName.CLUB_SINGLE_NEWS,
                path: 'articles',
                component: () => import('@/views/club/clubs/tabs/articles/ClubArticle.vue'),
                meta: {
                    layoutProps: {
                        extended: 'sm'
                    }
                }
            },
            {
                name: ClubRouteName.CLUB_SINGLE_RULES,
                path: 'rules',
                component: () => import('@/views/club/clubs/tabs/ClubRules.vue'),
                meta: {
                    layoutProps: {
                        extended: 'sm'
                    }
                }
            },
            {
                name: ClubRouteName.CLUB_SINGLE_VIDEOS,
                path: 'videos',
                component: () => import('@/views/club/clubs/tabs/videos/ClubVideos.vue'),
                meta: {
                    layoutProps: {
                        extended: 'sm'
                    }
                }
            },
            {
                name: ClubRouteName.CLUB_SINGLE_SOLUTIONS_AND_SERVICES,
                path: 'solutions-and-services',
                component: () => import('@/views/club/clubs/tabs/solutions-and-services/SolutionsAndServicesPage.vue'),
                meta: {
                    layoutProps: {
                        extended: 'sm'
                    }
                }
            }
        ]
    },
    {
        name: ClubRouteName.CLUB_SINGLE_JOIN_INVESTORS,
        path: '/clubs/investor/join',
        component: () => import(/* webpackChunkName: 'clubs' */ '@/views/club/clubs/investors/InvestorsClubJoin.vue'),
        meta: {
            hasGridBackground: true
        }
    },
    {
        name: ClubRouteName.CLUB_SINGLE_JOIN,
        path: '/clubs/:club/join',
        component: () => import(/* webpackChunkName: 'clubs' */ '@/views/club/clubs/join/ClubJoin.vue'),
        beforeEnter: (to) => {
            const { navigate } = useNavigationResolver()

            if (to.params.club === 'investor') {
                navigate({ name: ClubRouteName.CLUB_SINGLE_JOIN_INVESTORS })
            }
        },
        meta: {
            layoutProps: { noPartner: true }
        }
    },
    {
        name: ClubRouteName.CLUB_SINGLE_ASK_TO_JOIN,
        path: '/clubs/:club/ask-to-join',
        redirect: (to) => ({
            name: ClubRouteName.CLUB_SINGLE_JOIN,
            params: { club: to.params.club }
        })
    },
    {
        path: '/clubs/:club/projects/:project',
        name: ClubRouteName.CLUB_PROJECT_DETAILS,
        component: () => import('@/views/club/clubs/partials/dealflow/ClubProjectSingle.vue'),
        meta: {
            layoutProps: { extended: 'sm' }
        }
    },
    {
        name: ClubRouteName.MEMBER_RECOMMEND,
        path: '/member/recommend',
        component: () => import('@/views/club/recommend/RecommendPage.vue'),
        redirect: {
            name: ClubRouteName.MEMBER_RECOMMEND_HOW_TO
        },
        meta: {
            middleware: [requiredAuthentification, requiredRoleLeClub],
            hasGridBackground: true,
            onlyContentOnApp: true
        },
        children: [
            {
                name: ClubRouteName.MEMBER_RECOMMEND_HOW_TO,
                path: 'how-to',
                component: () => import('@/views/club/recommend/tabs/RecommendHowTo.vue'),
                meta: {
                    layoutProps: {
                        extended: 'sm'
                    }
                }
            },
            {
                name: ClubRouteName.MEMBER_RECOMMEND_IMPACT,
                path: 'impact',
                component: () => import('@/views/club/recommend/tabs/RecommendImpact.vue'),
                meta: {
                    layoutProps: {
                        extended: 'sm'
                    }
                }
            },
            {
                name: ClubRouteName.MEMBER_RECOMMEND_LIST,
                path: 'recommended',
                component: () => import('@/views/club/recommend/tabs/RecommendList.vue'),
                meta: {
                    layoutProps: {
                        extended: 'sm'
                    }
                }
            }
        ]
    },
    {
        path: '/unsubscribe',
        name: ClubRouteName.NEWSLETTER_UNSUBSCRIBE,
        component: () => import('@/views/common/newsletter/unsubscribe/UnsubscribePage.vue'),
        meta: {
            layout: EmptyLayout,
            context: SpaName.LECLUB
        }
    },
    {
        path: '/404',
        name: ClubRouteName.NOT_FOUND,
        component: () => import('@/views/NotFoundPage.vue'),
        meta: {
            layout: EmptyLayout
        }
    },
    {
        path: '/qrcode/:id',
        name: ClubRouteName.QRCODE,
        component: () => import('@/views/club/qrcode/QRcodePage.vue'),
        beforeEnter: () => {
            window.$crisp?.push(['do', 'chat:hide'])
        },
        meta: {
            layout: EmptyLayout,
            hideWhatsNew: true,
            hideCrisp: true
        }
    },
    {
        path: '/apply-vip',
        name: ClubRouteName.APPLY_VIP,
        component: () => import('@/views/applies/leclub/apply-vip/ApplyVIPClub.vue'),
        meta: {
            layout: ApplyLayout
        }
    },
    {
        path: '/apply-vip/finish',
        name: ClubRouteName.APPLY_VIP_FINISH,
        component: () => import('@/views/applies/leclub/apply-vip/ApplyVipClubFinish.vue'),
        meta: {
            layout: EmptyLayout
        }
    },
    {
        path: '/room/:roomId',
        name: ClubRouteName.ROOM,
        component: () => import('@/views/club/room/RoomConference.vue'),
        meta: {
            layout: EmptyLayout
        }
    },
    {
        // Use with ?search=<search>
        path: '/v2/search/all/',
        name: ClubRouteName.UNIVERSAL_SEARCH,
        component: () => import('@/views/club/universalSearch/UniversalSearchPage.vue'),
        meta: {
            middleware: [requiredAuthentification, requiredRoleLeClub]
        }
    },
    {
        path: '/members',
        name: ClubRouteName.MEMBER_LIST,
        component: () => import('@/views/club/members/list/MembersListPage.vue'),
        meta: {
            middleware: [requiredAuthentification, requiredRoleLeClub],
            hasGridBackground: true
        },
        redirect: {
            name: ClubRouteName.MEMBER_LIST_ALL
        },
        children: [
            {
                path: 'all',
                name: ClubRouteName.MEMBER_LIST_ALL,
                component: () => import('@/views/club/members/list/views/all/MemberListAll.vue')
            },
            {
                path: 'contacts',
                name: ClubRouteName.MEMBER_LIST_CONTACTS,
                component: () => import('@/views/club/members/list/views/contacts/MemberListContacts.vue')
            },
            {
                path: 'favorites',
                name: ClubRouteName.MEMBER_LIST_FAVORITES,
                component: () => import('@/views/club/members/list/views/favorites/MemberListFavorites.vue')
            },
            {
                path: 'suggestions',
                name: ClubRouteName.MEMBER_LIST_SUGGESTIONS,
                redirect: {
                    name: ClubRouteName.LISTING_MER
                }
            }
        ]
    },
    {
        path: '/member/:memberIdOrMe',
        alias: '/member-front/:memberIdOrMe',
        component: () =>
            paywallCheck({
                paywall: import('@/views/club/members/MemberPaywallPage.vue'),
                default: import('@/views/club/members/profile/MemberProfilePage.vue')
            }),
        children: [
            {
                name: ClubRouteName.MEMBER_PROFILE,
                path: '',
                component: () => import('@/views/club/members/profile/tabs/details/MemberProfileDetails.vue')
            },
            {
                name: ClubRouteName.MEMBER_PREFERENCES,
                path: 'preferences',
                component: () => import('@/views/club/members/profile/tabs/preferences/MemberProfilePreferences.vue')
            },
            {
                name: ClubRouteName.MEMBER_CHALLENGES_EXPERTIES,
                path: 'enjeux',
                component: () =>
                    import(
                        '@/views/club/members/profile/tabs/challenges-expertises/MemberProfileChallengesExpertises.vue'
                    )
            }
        ]
    },
    {
        path: '/future-leaders-pass',
        name: ClubRouteName.FUTURE_LEADERS_PASS,
        component: () => import('@/views/club/future-leaders-pass/FutureLeadersPassPage.vue'),
        meta: {
            hasGridBackground: true
        }
    },
    {
        path: '/:pathMatch(.*)*',
        redirect: () => ({
            name: ClubRouteName.NOT_FOUND
        })
    }
]

export default routes
