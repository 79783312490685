<script setup lang="ts">
    import { useI18n } from 'vue-i18n'
    import { useMutation, useQueryClient } from '@tanstack/vue-query'
    import { AxiosError } from 'axios'
    import { useForm } from 'vee-validate'
    import BaseButton from '@/components/ui/actions/BaseButton.v2.vue'
    import BaseModal from '@/components/ui/basics/BaseModal.v2.vue'
    import FormCheckbox from '@/components/ui/forms/FormCheckbox.v2.vue'
    import FormDate from '@/components/ui/forms/FormDate.vue'
    import FormGoogleLocationV2 from '@/components/ui/forms/FormGoogleLocation.v2.vue'
    import FormInput from '@/components/ui/forms/FormInput.vue'
    import AccountApi from '@/libs/api/Account.api'
    import useFutureLeadersPass from '@/libs/compositions/useFutureLeadersPass'
    import useNotification from '@/libs/compositions/useNotification'
    import { useAuthStore } from '@/libs/stores/auth.store'
    import { CompleteFutureLeadersPassForm } from '@/types/forms/account-api/FutureLeadersPassForm.type'
    import { ResponseFormError } from '@/types/response/ResponseV3.type'
    import { RoleName } from '@/vars/AuthAttr'
    import { ButtonType } from '@/vars/ButtonAttr'
    import { QueryName } from '@/vars/QueryAttr'
    import { ToastType } from '@/vars/ToastAttr'

    const isModalVisible = defineModel<boolean>()

    const { t } = useI18n()
    const authStore = useAuthStore()
    const queryClient = useQueryClient()
    const { toast } = useNotification()
    const { needImageAuthorization, minimumBirthDate, maximumBirthDate } = useFutureLeadersPass()

    const {
        handleSubmit,
        values: formValues,
        meta,
        setFieldValue
    } = useForm<CompleteFutureLeadersPassForm>({
        initialValues: {
            child: {
                firstName: '',
                lastName: '',
                email: '',
                birthDate: null,
                phone: '',
                phonePrefix: '+33',
                location: null,
                imageAuthorization: true
            }
        }
    })

    const { mutate: completeFlp, isPending } = useMutation<
        unknown,
        AxiosError<ResponseFormError<CompleteFutureLeadersPassForm>>,
        CompleteFutureLeadersPassForm
    >({
        mutationFn: (form) => AccountApi.completeFutureLeadersPass(form),
        onSuccess: () => {
            isModalVisible.value = false
            queryClient.invalidateQueries({ queryKey: [QueryName.GETTING_STARTED, import.meta.env.VITE_SPA_RUNNING] })
            queryClient.invalidateQueries({ queryKey: [QueryName.ACCOUNT_MEMBERSHIP_SUBSCRIPTIONS] })

            toast({
                type: ToastType.SUCCESS,
                title: t('success.completed.title'),
                text: t('success.completed.text')
            })
        },
        onError: (e) => {
            console.error(e)
            toast({
                type: ToastType.ERROR,
                title: t('error.generic.title'),
                text: t('error.generic.text')
            })
        }
    })

    function onChangeBirthDate() {
        if (formValues.child.birthDate && needImageAuthorization(formValues.child.birthDate)) {
            setFieldValue(`child.imageAuthorization`, false)
        }
    }

    const onSubmit = handleSubmit((validatedformValues) => {
        completeFlp(validatedformValues)
    })
</script>

<template>
    <BaseModal v-model="isModalVisible">
        <template #title>{{ t('finish_config') }}</template>
        <template #default>
            <form
                v-if="authStore.isLoggedIn && authStore.hasRole(RoleName.LECLUB)"
                class="@container"
                @submit.prevent="onSubmit"
            >
                <div class="mb-6 flex flex-col gap-2">
                    <div class="mb-4 grid grid-cols-1 gap-4 @md:grid-cols-2">
                        <FormInput
                            class="flex-1"
                            name="child.firstName"
                            type="text"
                            placeholder="Camille"
                            rules="required"
                            test-id="child-firstName"
                        >
                            {{ t('your_child_firstname') }}
                        </FormInput>
                        <FormInput
                            class="flex-1"
                            name="child.lastName"
                            type="text"
                            placeholder="Dupont"
                            rules="required"
                            test-id="child-lastname"
                        >
                            {{ t('your_child_lastname') }}
                        </FormInput>

                        <FormInput
                            class="flex-1"
                            name="child.email"
                            type="email"
                            placeholder="camille.dupont@gmail.com"
                            rules="required|email"
                            test-id="child-email"
                        >
                            {{ t('your_child_email') }}
                        </FormInput>
                        <FormDate
                            class="flex-1"
                            name="child.birthDate"
                            :max-date="maximumBirthDate"
                            :min-date="minimumBirthDate"
                            :start-date="minimumBirthDate"
                            test-id="child-birthdate"
                            rules="required"
                            placeholder="DD/MM/YYYY"
                            @update:model-value="() => onChangeBirthDate()"
                        >
                            {{ t('birthDate') }}
                        </FormDate>
                        <FormGoogleLocationV2
                            name="child.location"
                            rules="required"
                            :autocomplete-types="['(cities)']"
                            test-id="flp-location"
                        >
                            <template #label> {{ t('city') }} </template>
                        </FormGoogleLocationV2>
                        <FormInput
                            :phone-prefix="formValues.child.phonePrefix || '+33'"
                            type="tel"
                            name="child.phone"
                            rules="required"
                            :placeholder="t('phone_number')"
                            test-id="flp-phone"
                            @update:phone-prefix="setFieldValue(`child.phonePrefix`, $event)"
                        >
                            {{ t('phone') }}
                        </FormInput>
                        <FormCheckbox
                            v-if="formValues.child.birthDate && needImageAuthorization(formValues.child.birthDate)"
                            name="child.imageAuthorization"
                            class="text-small col-span-2 mb-4"
                            rules="required"
                        >
                            {{ t('image_authorization') }}
                        </FormCheckbox>
                    </div>
                </div>
                <BaseButton
                    class="w-full"
                    :pending="isPending"
                    :type="ButtonType.SUBMIT"
                    :disabled="!meta.valid"
                    test-id="flp-confirm"
                    data-complete-flp-action
                >
                    {{ t('finish_configuration') }}
                </BaseButton>
            </form>
        </template>
    </BaseModal>
</template>

<style lang="scss" scoped>
    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.5s ease;
    }

    .fade-enter-from,
    .fade-leave-to {
        opacity: 0;
    }
</style>

<i18n lang="json">
{
    "fr": {
        "complete_flp": "Vous avez souscrit au \"Future Leaders Pass\" pour un de vos enfants. Veuillez terminer la configuration afin qu'il puisse bénéficier des avantages auxquels il a désormais accès.",
        "finish_config": "Terminer la configuration",
        "finish_configuration": "Terminer la configuration",
        "your_child_email": "Email de votre enfant",
        "your_child_firstname": "Prénom de votre enfant",
        "your_child_lastname": "Nom de votre enfant",
        "add_child": "Ajouter un enfant",
        "buy_pass": "Prendre l'abonnement|Prendre l'abonnement — {count}$ / mois|Prendre l'abonnement — {count}$ / mois",
        "connect_ff_account": "Connectez-vous à votre compte Frenchfounders",
        "you_need_account": "Vous devez avoir un compte Frenchfounders avec un membership Club privé pour avoir accès à cette offre.",
        "sign_in": "Se connecter",
        "birthDate": "Date de naissance",
        "city": "Ville",
        "phone_number": "Numéro de téléphone",
        "phone": "Téléphone",
        "mentor_interest": "Je suis partant(e) pour accompagner un autre Futur Leader lors des événements physiques Frenchfounders.",
        "coupon": "Coupon",
        "free_pass": "Prendre l'abonnement gratuitement",
        "image_authorization": "J'autorise l'utilisation des photos/vidéos de mon futur leader dans le cadre des activités du Future Leaders Pass.",
        "future_leaders_pass_number": "Future Leaders Pass n° {number}",
        "error": {
            "invalid_coupon": {
                "title": "Coupon invalide",
                "text": "Veuillez saisir un coupon valide"
            }
        },
        "success": {
            "completed": {
                "title": "Configuration terminée",
                "text": "Votre enfant va recevoir un email pour terminer son inscription."
            }
        }
    }
}
</i18n>
