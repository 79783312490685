<script setup lang="ts">
    import AvatarMedia from '@/components/ui/medias/AvatarMedia.vue'
    import DropdownContent from '@/components/ui/navigations/dropdowns/DropdownContent.vue'
    import DropdownItem from '@/components/ui/navigations/dropdowns/DropdownItem.vue'
    import DropdownNavigation from '@/components/ui/navigations/dropdowns/DropdownNavigation.vue'
    import LocaleSelector from '@/components/layouts/common/__partials__/LocaleSelector.vue'
    import { useAuthStore } from '@/libs/stores/auth.store'
    import { HeaderNavigation } from '@/types/Header.type'
    import { useI18n } from 'vue-i18n'
    import BaseIcon from '@/components/ui/basics/BaseIcon.vue'
    import RegisterModal from '@/components/partials/register/RegisterModal.vue'
    import { ref } from 'vue'
    import { SpaName } from '@/vars/SpaAttr'
    import { AvatarSize } from '@/vars/AvatarAttr'
    import { RouteName } from '@/vars/RouteName'
    import SpaRouterLink from '@/components/ui/navigations/SpaRouterLink.vue'
    import useMenuNavigationState from '@/components/layouts/common/header/navigations/profile/partials/useMenuNavigationState'

    defineProps<{
        profile: HeaderNavigation[]
        noSpace?: boolean
    }>()

    const redirectUrl = window.location.href
    const spaRunning = import.meta.env.VITE_SPA_RUNNING
    const store = useAuthStore()
    const { t } = useI18n()
    const { isOpened } = useMenuNavigationState()

    const showRegisterModal = ref(false)

    function handleRegister() {
        showRegisterModal.value = true
    }
</script>

<template>
    <nav class="nav" :class="{ 'nav--spaced': !noSpace }">
        <slot name="controls"></slot>
        <DropdownNavigation
            show-on-click
            class="nav__profile"
            :auto-close-delay-millis-after-leave="100000"
            @open="isOpened = true"
            @close="isOpened = false"
        >
            <template #activator>
                <div
                    class="nav__thumb"
                    :class="{
                        'nav__thumb--dark': spaRunning === SpaName.LECLUB,
                        'nav__thumb--network': spaRunning === SpaName.LENETWORK
                    }"
                >
                    <svg
                        width="14"
                        height="12"
                        viewBox="0 0 14 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        class="nav__icon"
                        :class="{ 'hidden lg:block': spaRunning === SpaName.LENETWORK }"
                    >
                        <rect width="14" height="1.5" fill="#FFFFFF" />
                        <rect y="5" width="14" height="1.5" fill="#FFFFFF" />
                        <rect y="10" width="14" height="1.5" fill="#FFFFFF" />
                    </svg>
                    <AvatarMedia
                        v-if="store.authentifiedUser?.picture"
                        :src="store.authentifiedUser?.picture"
                        :alt="store.authentifiedUser?.full_name || ''"
                        :size="AvatarSize.MINI"
                        class="nav__avatar"
                    />
                    <BaseIcon v-else name="account_circle" class="nav__avatar--anonymous" />
                </div>
            </template>
            <template #dropdown>
                <DropdownContent v-if="store.isLoggedIn" class="nav__content" align="left">
                    <template v-for="(section, index) in profile" :key="`section-${index}`">
                        <section class="section">
                            <div v-if="section.label" class="section__head">
                                <div v-if="section.icon" class="section__icon-wrapper">
                                    <img :src="section.icon" :alt="section.label" class="section__icon" />
                                </div>
                                <template v-if="section.href">
                                    <a :href="section.href" class="section__link">
                                        <h5 class="section__title">
                                            {{ section.label }}
                                        </h5>
                                        <small v-if="section.subtitle" class="section__subtitle">
                                            {{ section.subtitle }}
                                        </small>
                                    </a>
                                </template>
                                <template v-else>
                                    <h5 class="section__title">
                                        {{ section.label }}
                                    </h5>
                                    <small v-if="section.subtitle" class="section__subtitle">
                                        {{ section.subtitle }}
                                    </small>
                                </template>
                            </div>
                            <div v-if="section.children && section.children.length > 0" class="section__items">
                                <DropdownItem
                                    v-for="dropdownItem in section.children"
                                    :key="dropdownItem.label"
                                    class="section__item"
                                    :href="dropdownItem.href"
                                    :tag="dropdownItem.route ? 'RouterLink' : dropdownItem.href ? 'a' : 'div'"
                                    :to="dropdownItem.route"
                                    @click="
                                        typeof dropdownItem.action !== 'undefined' ? dropdownItem.action() : undefined
                                    "
                                >
                                    {{ dropdownItem.label }}
                                </DropdownItem>
                            </div>

                            <div v-if="section?.special?.key === 'locale_selector'" class="section__locale_selector">
                                <LocaleSelector />
                            </div>
                        </section>
                        <hr v-if="index === profile.length - 2" />
                    </template>
                </DropdownContent>
                <DropdownContent v-else class="nav__content" align="left">
                    <section class="section">
                        <div class="section__items">
                            <SpaRouterLink :to="{ name: RouteName.LOGIN, query: { redirectUrl } }">
                                <DropdownItem class="section__item" tag="div">
                                    {{ t('login') }}
                                </DropdownItem>
                            </SpaRouterLink>
                            <DropdownItem class="section__item" tag="div" @click="handleRegister">
                                {{ t('register') }}
                            </DropdownItem>
                        </div>
                    </section>
                </DropdownContent>
            </template>
        </DropdownNavigation>

        <RegisterModal
            :model-value="showRegisterModal"
            from="header"
            @update:model-value="showRegisterModal = false"
            @success="showRegisterModal = false"
        />
    </nav>
</template>

<style scoped lang="scss">
    @import '@/libs/sass/vars';
    .nav {
        $block-selector: &;
        height: 100%;
        display: flex;

        &--spaced {
            flex: 1;
            justify-content: flex-end;

            @media only screen and (min-width: $breakpoints-lg) {
                flex: initial;
                justify-content: initial;
                margin-left: 2em;
            }
        }

        &__profile {
            position: relative;
        }

        &__thumb {
            display: flex;
            align-items: center;

            &--network {
                @media screen and (max-width: $breakpoints-lg) {
                    padding: 0 !important;
                    border: none !important;
                }
            }

            @media only screen and (min-width: $breakpoints-lg) {
                padding: 0.375em 0.375em 0.375em 0.875em;
                border-radius: (0.5em * 2) + (0.375em * 2);
                background-color: var(--theme-background-color--header-pill);
                border: 1px solid var(--theme-border-color--header-pill);

                &--dark {
                    background-color: rgba(242, 242, 242, 0.1) !important;
                    border: transparent;
                }
            }
        }

        &__icon {
            margin-right: 0.8em;

            * {
                fill: var(--theme-text-color--header);
            }
        }
        &__avatar {
            font-size: 0.5em;
            &--anonymous {
                font-size: 1.5em;
                color: var(--theme-color-gray--04);
            }
        }

        &__content {
            width: 18em;
        }
    }

    .section {
        $block-selector: &;
        margin: 0 0 1em 0;

        &:last-child {
            margin: 0;
        }

        &__head {
            display: flex;
            align-items: center;
            padding: 0 1.5rem;
            margin-bottom: 0.5rem;
        }
        &__link {
            display: block;
            padding-bottom: 0.5rem;
        }
        &__icon-wrapper {
            width: 2.25em;
            height: 2.25em;
            padding: 0.25rem;
            margin-right: 0.5rem;
            border: 1px solid var(--theme-border-color);
            border-radius: 0.125em;
        }
        &__icon {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
        &__title {
            font-size: 1em;
            font-weight: 600;
            margin: 0;
            padding: 0;

            #{$block-selector}__link & {
                padding-bottom: 0;
            }
        }
        &__subtitle {
            font-size: 0.75em;
        }

        &__locale_selector {
            display: flex;
            font-size: 1em;
            font-weight: 600;
            padding: 0.5rem 1.5rem;
        }
        &__item {
            padding: 0.5em 1.5em !important;
        }
    }

    hr {
        border: none;
        border-top: 1px solid #e0e0e0;
        box-shadow: none;
        margin: 1em 0;
    }
</style>

<i18n lang="json">
{
    "fr": {
        "login": "Me connecter",
        "register": "M'inscrire"
    },
    "en": {
        "login": "Login",
        "register": "Register"
    }
}
</i18n>
