<script setup lang="ts">
    import { computed, ref } from 'vue'
    import { useI18n } from 'vue-i18n'
    import ModalCompleteFutureLeadersPass from '@/components/partials/club/ModalCompleteFutureLeadersPass.vue'
    import useGettingStartedTodoQuery from '@/libs/queries/useGettingStartedTodoQuery'

    const { t } = useI18n()

    const { data: todoList } = useGettingStartedTodoQuery()

    const isConfigurationModalVisible = ref(false)

    const isBannerVisible = computed(() => {
        if (!todoList.value) {
            return false
        }

        return todoList.value.data.some((todo) => todo.id === 'complete_flp')
    })
</script>

<template>
    <div v-if="isBannerVisible">
        <div
            class="relative z-[110] flex w-full items-center justify-between gap-10 bg-royale px-6 py-2 text-sm font-medium text-white"
        >
            <div>
                {{ t('complete_flp') }}
            </div>

            <button type="button" class="underline" @click="isConfigurationModalVisible = true">
                {{ t('finish_config') }}
            </button>
        </div>
        <ModalCompleteFutureLeadersPass v-model="isConfigurationModalVisible" />
    </div>
</template>

<i18n lang="json">
{
    "fr": {
        "complete_flp": "Vous avez souscrit au \"Future Leaders Pass\" pour un de vos enfants. Veuillez terminer la configuration afin qu'il puisse bénéficier des avantages auxquels il a désormais accès.",
        "finish_config": "Terminer la configuration"
    }
}
</i18n>
